import React, { useEffect, useReducer, useRef, useState } from 'react';
import {
  Button,
  Flex,
  Icon,
  Loader,
  Popup,
  Switch,
  Tooltip,
} from 'elements_v2';
import Card from 'elements_v2/Card';
import asyncPool from 'utils/async-pool';
import Progress from 'elements_v2/Progress';
import { useUserContext } from 'hooks';

const swap = (arr, x, y) =>
  arr.length > 1 ? (([arr[x], arr[y]] = [arr[y], arr[x]]), arr) : arr;

export function Slide(props) {
  const { index, data: value, onRemove, onChange } = props;
  const uploadButton = useRef();
  const [copiedFile, setCopiedFile] = useReducer(
    (state, action) => ({ ...action }),
    props.copiedFile,
  );
  const [isUploading, setIsUploading] = useState(false);
  const [isCloned, setIsCloned] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const [file, setFile] = useState();
  const ref = useRef();

  useEffect(() => {
    if (file) {
      uploadFile();
      setIsUploading(true);
    }
  }, [file]);

  async function cloneSlide() {
    setIsCloning(true);
    delete value._id;

    const copiedFile = await props.copySlideToFile(value, index);
    setCopiedFile(copiedFile);
    setIsCloning(false);
    setIsCloned(true);
  }

  async function uploadFile() {
    const controller = new AbortController();
    if (value?.previews?.[0]?.id) {
      file.id = value?.previews?.[0]?.id;
    }
    const uploadedFile = await props.context.uploadFile(file, 'thumbnails', {
      onProgress: () => {},
      abort: controller,
      onFinish: () => {},
      onError: (error) => console.error(error),
    });
    onChange({
      ...value,
      previews: [uploadedFile],
    });
    setFile(null);
    setIsUploading(false);
  }

  return (
    <>
      <Card
        style={{
          maxWidth: '25%',
          minWidth: 230,
          cursor: '',
          opacity: 1,
        }}
        ref={ref}
      >
        <Card.Header>
          <h1>
            Slide #{index + 1}
            <Icon name="Trash" bubble button onClick={() => onRemove(value)} />
          </h1>
        </Card.Header>
        <Card.Image>
          <input
            type="file"
            style={{ display: 'none' }}
            ref={uploadButton}
            accept="images/*"
            onChange={(event) => {
              const files = Array.from(event.target.files);
              const uploadedFile = files[0];
              setFile(uploadedFile);
            }}
          />
          {value.previews?.[0] && !isUploading ? (
            <div className="EditSlides__Image">
              <div className="EditSlides__Image__toolbar">
                <Button
                  as="div"
                  primary
                  background="#252525"
                  inline
                  size="tiny"
                  onClick={() => uploadButton?.current?.click()}
                >
                  <Icon name="CloudArrowUpFill" />
                  Replace thumbnail
                </Button>
              </div>
              <img src={value.previews?.[0]?.url} style={{ maxWidth: 150 }} />
            </div>
          ) : (
            <>
              {!file && (
                <Flex style={{ padding: '49px 25px', background: '#323232' }}>
                  <Button
                    as="div"
                    outlined
                    color="white"
                    onClick={() => uploadButton?.current?.click()}
                  >
                    {!props?.value?.url
                      ? 'Upload thumbnail'
                      : 'Replace thumbnail'}
                  </Button>
                </Flex>
              )}
              {isUploading && <Loader>Uploading...</Loader>}
            </>
          )}
        </Card.Image>
        <Card.Actions>
          <Flex width="100%" column>
            {!copiedFile ? (
              <Button
                onClick={cloneSlide}
                loading={isCloning || props.isCopyingAllSlides}
              >
                <Icon name="Stickies" />
                Create a copy
              </Button>
            ) : (
              <>
                <Button disabled>
                  <Icon name="CheckCircle" />
                  {isCloned ? 'Copied!' : 'Slide already copied'}
                </Button>
                <Button
                  style={{ marginTop: 10 }}
                  onClick={() =>
                    props.setSelectedFiles({
                      action: 'single',
                      data: copiedFile,
                    })
                  }
                >
                  <Icon name="Pencil" />
                  Edit copied file
                </Button>
              </>
            )}
            {/* <Button as="a" href={value.url} download={decodeURIComponent(value.name)} target="_BLANK">
              <Icon name="CloudDownload" />
              Download
            </Button> */}
          </Flex>
        </Card.Actions>
      </Card>
    </>
  );
}

export default function EditSlides(props) {
  const User = useUserContext();
  const [slides, setSlides] = useReducer((state, action) => [...action], []);
  const [slidesPublished, setSlidesPublished] = useState(
    props.file.file.slides_published,
  );
  const [alreadySplitSlides, setAlreadySplitSlides] = useReducer(
    (state, action) => [...action],
    [],
  );
  const [isCopying, setIsCopying] = useState(false);
  const [copiedSlides, increaseCopiedSlides] = useReducer(
    (state) => state + 1,
    0,
  );
  const [totalSlides, setTotalSlides] = useState(0);
  const [isAllFilesCopied, setIsAllFilesCopied] = useState(false);

  async function getAlreadySplitSlides() {
    const files = await props.context.importCheckReferences(
      'powerpoint_file_slides',
      props.file._id,
    );
    setAlreadySplitSlides(files);
  }

  async function copyAllSlides() {
    const slidesToCopy = slides
      .map((slide, index) => ({ ...slide, index }))
      .filter(
        (slide) =>
          !alreadySplitSlides.find(
            (file) => file.import?.referenceLocation === slide?.id,
          ),
      );
    setIsCopying(true);
    setTotalSlides(slidesToCopy.length);
    await asyncPool(10, slidesToCopy, async (slide) => {
      await copySlideToFile(slide, slide.index);
      increaseCopiedSlides();
    });
    setIsCopying(false);
    setIsAllFilesCopied(true);
  }

  async function copySlideToFile(value, index) {
    delete value._id;

    const copiedFile = await props.context.importMedia(
      value.url,
      {
        tags: props.file.tags,
        description: props.file.description,
        name: `${props.file.name} – Slide #${index + 1}`.replace('.pptx', ''),
        file: {
          ...value,
          ext: 'pptxslide',
        },
        import: {
          from: 'powerpoint_file_slides',
          reference: props.file._id,
          referenceLocation: value.id,
        },
      },
      {},
      {},
      {
        thumbnail: {
          url: value.previews?.[0]?.url,
          name: value.previews?.[0]?.name,
        },
      },
    );
    setAlreadySplitSlides([...alreadySplitSlides, copiedFile]);
    return copiedFile;
  }

  useEffect(() => {
    if (props.isEditing && props.file.file.slides) {
      setSlides(props.file.file.slides);
      setSlidesPublished(props.file.file.slides_published);
      if (props.file.file.slides?.length) {
        getAlreadySplitSlides(props.file.file.slides);
      }
    }
  }, [props.file.file.slides, props.isEditing]);

  useEffect(() => {
    setSlidesPublished(props.file.file.slides_published);
  }, [props.file.file.slides_published]);

  const file = props.context.data.files.find(
    (row) => row._id === props.file?._id,
  );

  if (file.processing?.slides?.isProcessing) {
    return <Loader>Generating slides</Loader>;
  }
  return (
    <>
      <div style={{ display: 'flex', paddingTop: 10 }}>
        <Button
          pixi
          as="div"
          inline
          onClick={() =>
            props.context.reParseFile(props.file?._id, {
              slides: true,
            })
          }
        >
          <Icon name="LightningChargeFill" />
          {!props.file?.file?.slides?.length
            ? 'Autogenerate'
            : 'Regenerate slides'}
        </Button>

        <Switch
          label={
            <>
              Enable previewing and inserting indivdual slides from this
              PowerPoint
            </>
          }
          inline
          active={
            slidesPublished ??
            User.data.selectedCommunity.settings?.preferences
              ?.enableQuickSlidePreview !== false
          }
          onChange={(val) => {
            setSlidesPublished(val);
            props.onSlidesPublished(val);
          }}
        />
      </div>
      <Flex
        alignItems="center"
        style={{ marginTop: 15, marginRight: 23 }}
        justifyContent="space-between"
      >
        {isAllFilesCopied ? (
          <Button disabled inline>
            <Icon name="CheckCircle" />
            All slides duplicated
          </Button>
        ) : isCopying ? (
          <Progress
            style={{ maxWidth: 400 }}
            currentLabelPercentage
            maxLabel={`Duplicating ${totalSlides} slides`}
            current={copiedSlides}
            max={totalSlides}
          />
        ) : (
          <Flex alignItems="center">
            <Button inline onClick={copyAllSlides}>
              Create a copies of all slides
            </Button>
            <div style={{ marginLeft: 15, color: '#8A8A8A' }}>
              A copy creates a separate, standalone version of your slides.
            </div>
          </Flex>
        )}
      </Flex>
      <Flex width="100%" flexWrap="wrap" style={{ marginLeft: -10 }}>
        {slides.map((value, key) => (
          <Slide
            index={key}
            context={props.context}
            onRemove={(value) =>
              setSlides(slides.filter((slide) => slide.id !== value.id))
            }
            setSlides={setSlides}
            onMove={(fromKey, toKey) => {
              setSlides(swap([...slides], fromKey, toKey));
            }}
            onChange={(value) => {
              const newSlides = slides.map((slide) =>
                slide.id === value.id ? value : slide,
              );
              setSlides(newSlides);
              if (props.onSave) {
                props.onSave(newSlides, slidesPublished);
              }
            }}
            copySlideToFile={copySlideToFile}
            copiedFile={alreadySplitSlides.find(
              (file) => file.import?.referenceLocation === value?.id,
            )}
            document={props.file}
            key={value.id}
            data={value}
            isCopyingAllSlides={isCopying}
            setSelectedFiles={props.setSelectedFiles}
          />
        ))}
      </Flex>
    </>
  );
}
