import { useAssetEditPanel } from '../../context';
import { Detail, SectionCollapse } from '../../elements';
import { useUserContext } from 'hooks';
import PropertyValueForm from 'views/_Manage/Library/workflows/Properties/PropertyValueForm';
import PropertyValue from 'views/_Manage/Library/workflows/Properties/PropertyValue';
import { uniqBy } from 'lodash';
import useUserStatus from 'hooks/useUserStatus';
import { createAppToast } from '@pixi/AppController';
import { propertyValueToString } from 'views/_Manage/Library/workflows/Properties/propertValueToString';

export default function AssetEditPanelProperties() {
  const User = useUserContext();
  const userStatus = useUserStatus();

  const { file, files, context } = useAssetEditPanel();

  if (!userStatus.activeFeatures?.attributes) {
    return <></>;
  }
  const libraries = (User.data?.selectedCommunity?.libraries ||
    []) as Pickit.CommunityLibraryInterface[];
  const properties = (User.data?.selectedCommunity?.properties?.data ||
    []) as Pickit.CommunityPropertyInterface[];
  const librariesInFile = files?.length
    ? files?.reduce((acc: string[], file) => {
        if (!file.libraries?.length) {
          file.libraries = [context.type];
        }
        return [...new Set([...acc, ...(file.libraries || [])])];
      }, [])
    : !file?.libraries?.length
      ? [context.type]
      : file?.libraries || [context.type];
  const activeLibraries = librariesInFile.map((libraryNameId: string) =>
    libraries.find((l) => l.nameId === libraryNameId),
  );
  const activeProperties = activeLibraries
    ?.reduce((acc: string[], library) => {
      return [...acc, ...(library?.preferences.properties || [])];
    }, [])
    ?.filter((p) => !!properties.find((l) => l._id === p))
    .map((p) => {
      return properties.find((l) => l._id === p);
    }) as Pickit.CommunityPropertyInterface[];

  return (
    <>
      <SectionCollapse label="Properties">
        {uniqBy(activeProperties, '_id').map((p) => {
          const value = file.properties?.[
            p._id as keyof typeof file.properties
          ] as Pickit.PropertyValue;

          // if (!value) {
          //   return <></>;
          // }

          return (
            <Detail
              key={p.updatedAt}
              left={p.name}
              tooltip={
                propertyValueToString({ property: p, value })?.length > 25
                  ? propertyValueToString({ property: p, value })
                  : ''
              }
              leftTooltip={p.name?.length > 15 ? p.name : ''}
              right={
                !value ? undefined : (
                  <PropertyValue property={p} key={p._id} value={value} />
                )
              }
              rightNoText={!value?.[p.type]}
              actions={[
                {
                  label: 'edit',
                  icon: 'pen',
                  wrapper: (target) => (
                    <PropertyValueForm
                      key={p.updatedAt}
                      inline={!value?.[p.type]}
                      onConfirm={async (data) => {
                        if (!p._id) {
                          throw new Error('Property is corrupt');
                        }
                        if (files?.length) {
                          await context.saveDocumentsWhere(
                            {
                              _id: { $in: files.map((file) => file._id) },
                            },
                            {
                              [`properties.${p._id}`]: data,
                            },
                          );
                          await createAppToast({
                            id: 'property_' + p._id + files?.[0]?._id,
                            message: `Property saved`,
                            type: 'success',
                          });
                          return data;
                        }
                        await context.savePartial({
                          _id: file._id,
                          [`properties.${p._id}`]: data,
                        });
                        await createAppToast({
                          id: 'property_' + p._id + file?._id,
                          message: `Property saved`,
                          type: 'success',
                        });
                        return data;
                      }}
                      property={p}
                      value={
                        file.properties?.[
                          p._id as keyof typeof file.properties
                        ] || {}
                      }
                      target={target}
                    />
                  ),
                },
              ]}
            />
          );
        })}
      </SectionCollapse>
    </>
  );
}
