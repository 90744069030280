// import { Box, Indicator, Paper, PaperProps, Stack } from '@mantine/core';
// import PixiButton from '@pixi/elements/Button';
// import PixiIcon from '@pixi/elements/Icon';
// import { getMultiStore, useDataStoreCallback } from '@pixi/store';
// import { useEffect, useState } from 'react';
// import AssetEditPanel from '../AssetPanel';
// import { SectionCollapse, Title } from '../AssetPanel/elements';
// import { useAssetGridContext } from './AssetGridContext';
// import { AssetGridRightPanelProps } from './Helpers';
// import useFullViewportHeight from '@pixi/hooks/useFullViewportHeight';
// import AssetGridStats from './components/AssetGridStats';

// export default function AssetGridRightPanel({
//   keepPlaceholder,
//   ...rest
// }: AssetGridRightPanelProps) {
//   const [isHidden, setIsHidden] = useState(true);
//   const [isForceHidden, setIsForceHidden] = useState(false);
//   const { selectedFiles, id, activeShortcutIds } = useAssetGridContext();
//   const { ref, height } = useFullViewportHeight();
//   const selectedFilesData =
//     useDataStoreCallback(
//       'FILES',
//       (data, store) => {
//         return selectedFiles
//           .filter((file) => !!store.getByKey(file._id))
//           .map((file) => store.getByKey(file._id) as Pickit.FileInterface);
//       },
//       [selectedFiles],
//     ) || [];

//   useEffect(() => {
//     if (selectedFiles?.length && isHidden && !isForceHidden) {
//       setIsHidden(false);
//     } else if (!selectedFiles?.length) {
//       // setIsHidden(true);
//       setIsForceHidden(false);
//     }
//   }, [selectedFiles?.length]);

//   if (keepPlaceholder) {
//     return (
//       <Box
//         w="100%"
//         maw={350}
//         style={{
//           flexShrink: 0,
//           overflow: 'hidden',
//         }}
//       />
//     );
//   }

//   return (
//     <>
//       <Paper
//         w="100%"
//         maw={!isHidden ? 350 : 64}
//         mih={1}
//         pos="sticky"
//         top={0}
//         ref={ref}
//         bg="rgba(255, 255, 255, 0.5)"
//         style={{
//           height,
//           flexShrink: 0,
//           transition: 'max-width .15s',
//           overflow: 'hidden',
//         }}
//         {...rest}
//       >
//         {isHidden || !selectedFilesData?.length ? (
//           <Stack
//             w="100%"
//             justify="center"
//             align="center"
//             p={isHidden ? 'xs' : undefined}
//           >
//             {!selectedFilesData?.length && !isHidden ? (
//               <>
//                 <SectionCollapse
//                   label={<>0 files selected</>}
//                   noChevron
//                   noDivider
//                   onToggle={() => {
//                     setIsForceHidden(true);
//                     setIsHidden(true);
//                   }}
//                   paperProps={{
//                     bg: 'transparent',
//                   }}
//                   leftSection={
//                     <PixiButton
//                       px="xs"
//                       size="md"
//                       color="dark"
//                       variant="subtle"
//                       style={{ flexShrink: 0 }}
//                       onClick={() => {
//                         setIsForceHidden(false);
//                         setIsHidden(false);
//                       }}
//                     >
//                       <PixiIcon name="arrow-right-from-line" />
//                     </PixiButton>
//                   }
//                 />
//                 {/* <AssetGridStats /> */}
//               </>
//             ) : (
//               <Box w="100%" mt="9">
//                 <Indicator
//                   w="100%"
//                   position="top-end"
//                   disabled={!selectedFilesData?.length}
//                   offset={4}
//                   size={12}
//                 >
//                   <PixiButton
//                     fullWidth
//                     color="dark"
//                     variant="light"
//                     px="xs"
//                     size="md"
//                     onClick={() => {
//                       setIsForceHidden(false);
//                       setIsHidden(false);
//                     }}
//                   >
//                     <PixiIcon name="circle-info" />
//                   </PixiButton>
//                 </Indicator>
//               </Box>
//             )}
//           </Stack>
//         ) : (
//           <AssetEditPanel
//             files={selectedFilesData}
//             onClose={() => {
//               getMultiStore('FILES_SELECTED', id).reset();
//             }}
//             focus={
//               activeShortcutIds?.find((p) => p && p?.includes('approvals'))
//                 ? 'approvals'
//                 : activeShortcutIds?.find((p) => p && p.includes('feedback'))
//                   ? 'feedback'
//                   : undefined
//             }
//             onHide={(isForceHidden) => {
//               setIsForceHidden(!!isForceHidden);
//               setIsHidden(true);
//             }}
//           />
//         )}
//       </Paper>
//     </>
//   );
// }

import {
  Box,
  Divider,
  Indicator,
  Paper,
  PaperProps,
  Stack,
} from '@mantine/core';
import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import { getMultiStore, useDataStoreCallback } from '@pixi/store';
import { useEffect, useState } from 'react';
import AssetEditPanel from '../AssetPanel';
import { SectionCollapse, Title } from '../AssetPanel/elements';
import { useAssetGridContext } from './AssetGridContext';
import { AssetGridRightPanelProps } from './Helpers';
import useFullViewportHeight from '@pixi/hooks/useFullViewportHeight';
import AssetGridStats from './components/AssetGridStats';
import { useColorScheme } from '@pixi/AppController';

export default function AssetGridRightPanel({
  keepPlaceholder,
  ...rest
}: AssetGridRightPanelProps) {
  // const [isHidden, setIsHidden] = useState(true);
  // const [isForceHidden, setIsForceHidden] = useState(false);
  const { selectedFiles, id, activeShortcutIds } = useAssetGridContext();
  const colorScheme = useColorScheme();
  const { ref, height } = useFullViewportHeight();
  const [isHidden, setIsHidden] = useState(false);
  const selectedFilesData =
    useDataStoreCallback(
      'FILES',
      (data, store) => {
        return selectedFiles
          .filter((file) => !!store.getByKey(file._id))
          .map((file) => store.getByKey(file._id) as Pickit.FileInterface);
      },
      [selectedFiles],
    ) || [];

  if (keepPlaceholder) {
    return (
      <Box
        w="100%"
        maw={350}
        style={{
          flexShrink: 0,
          overflow: 'hidden',
        }}
      />
    );
  }

  return (
    <>
      <Paper
        w="100%"
        maw={!isHidden ? 350 : 64}
        mih={1}
        pos="sticky"
        top={0}
        ref={ref}
        bg={colorScheme === 'dark' ? 'transparent' : 'rgba(255, 255, 255, 0.5)'}
        style={{
          height,
          flexShrink: 0,
          transition: 'max-width .15s',
          overflow: 'hidden',
        }}
        {...rest}
      >
        {isHidden || !selectedFilesData?.length ? (
          <Stack
            w="100%"
            justify="center"
            align="center"
            p={isHidden ? 'xs' : undefined}
          >
            {!selectedFilesData?.length && !isHidden ? (
              <Stack w="100%" gap="0">
                <Title
                  title="Status"
                  onClose={() => {
                    setIsHidden(true);
                  }}
                  reversed
                />
                <Divider />
                <AssetGridStats />
              </Stack>
            ) : (
              <Box w="100%" mt="9">
                <Indicator
                  offset={2}
                  disabled={!selectedFiles?.length}
                  size="18"
                  label={selectedFiles?.length}
                >
                  <PixiButton
                    fullWidth
                    color="dark"
                    variant="light"
                    px="xs"
                    size="md"
                    onClick={() => {
                      setIsHidden(false);
                    }}
                  >
                    <PixiIcon
                      name={selectedFiles?.length ? 'circle-info' : 'chart-pie'}
                    />
                  </PixiButton>
                </Indicator>
              </Box>
            )}
          </Stack>
        ) : (
          <AssetEditPanel
            files={selectedFilesData}
            onClose={() => {
              getMultiStore('FILES_SELECTED', id).reset();
            }}
            onHide={() => {
              setIsHidden(true);
            }}
            focus={
              activeShortcutIds?.find((p) => p && p?.includes('approvals'))
                ? 'approvals'
                : activeShortcutIds?.find((p) => p && p.includes('feedback'))
                  ? 'feedback'
                  : undefined
            }
          />
        )}
      </Paper>
    </>
  );
}
