import { FileThumbnailV2 } from 'components/FileThumbnail';
import ImageEditor from 'components/ImageEditor';
import { SelectContributorUsersPixi } from 'components/SelectContributorUsersPixi';
import SelectExternalUsers from 'components/SelectExternalUsers';
import SelectGroupsDropdown from 'components/SelectGroupsDropdown';
import SelectUsersDropdown from 'components/SelectUsersDropdown';
import { usePickitDrive } from 'contexts/Providers/PickitDriveProvider';
import { ToastService } from 'elements';
import DocumentCollection from 'elements/DocumentCollection';
import PublishedFolder from 'elements/PublishedFolder';
import {
  Button,
  Dropdown,
  Flex,
  Form,
  Icon,
  Input,
  Message,
  List,
  Popup,
  Switch,
} from 'elements_v2';
import CheckboxCards from 'elements_v2/CheckboxCards';
import { useDeepState, useMediaContext, useUserContext } from 'hooks';
import useStorageUrl from 'hooks/files/useStorageUrl';
import * as React from 'react';
import { shuffle } from 'utils/random';
import { useAppContext } from 'contexts/Providers/AppProvider';
import SelectCollections from 'components/SelectCollections';
import useLibraries from '../../../../hooks/useLibraries';
import Confirm from '../../../../elements_v2/Confirm/index';
import { selectFile } from '@pixi/AppController';

const SyncToCollection = ({ item, library, onChange }) => {
  const Libraries = useLibraries();
  const App = useAppContext();

  const collection = Libraries[library].data.collections?.find(
    (c) => c._id === item?.publish?.libraries?.[library]?.collection,
  );

  React.useEffect(() => {
    if (
      !collection?._id &&
      typeof item?.publish?.libraries?.[library]?.collection === 'string'
    ) {
      onChange?.({
        publish: {
          libraries: {
            ...item?.publish?.libraries,
            [library]: {
              ...item?.publish?.libraries?.[library],
              collection: false,
            },
          },
        },
      });
    }
  }, [collection, item]);

  return (
    <>
      {collection?._id ? (
        <Flex style={{ gap: 15, marginBottom: 15 }}>
          <Input.Field
            card
            label={
              <div>
                Automatically adding all {Libraries[library].type} to collection{' '}
                <strong>{collection?.name}</strong>
              </div>
            }
            style={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
            custom={
              <>
                <Flex>
                  {/* <Button
                    inline
                    primary
                    onClick={() => App.toggleEdit('collection', collection)}
                  >
                    <Icon name="pencil-fill" />
                    Edit collection
                  </Button> */}
                  <SelectCollections
                    dropdown
                    context={Libraries[library]}
                    ignoreFamily
                    dropdownProps={{ width: 300, noTriggerWrapper: true }}
                    onChange={(collection) => {
                      onChange?.({
                        publish: {
                          libraries: {
                            ...item?.publish?.libraries,
                            [library]: {
                              ...item?.publish?.libraries?.[library],
                              collection: collection._id,
                            },
                          },
                        },
                      });
                    }}
                    trigger={
                      <Button inline>
                        <Icon name="collection" />
                        Change collection
                      </Button>
                    }
                  />
                  <Button
                    inline
                    onClick={() => {
                      onChange?.({
                        publish: {
                          libraries: {
                            ...item?.publish?.libraries,
                            [library]: {
                              ...item?.publish?.libraries?.[library],
                              collection: null,
                            },
                          },
                        },
                      });
                    }}
                  >
                    <Icon name="x-lg" />
                    Remove sync
                  </Button>
                </Flex>
              </>
            }
          />
          <DocumentCollection
            context={Libraries[library]}
            style={{ padding: 0 }}
            collection={collection}
          />
        </Flex>
      ) : (
        <Input.Field
          card
          label={`Automatically add all ${library} to a collection`}
          description="Files added to the folder will automatically be added to the collection. This can easily be turned off."
          custom={
            <SelectCollections
              dropdown
              context={Libraries[library]}
              ignoreFamily
              dropdownProps={{ width: 300, noTriggerWrapper: true }}
              onChange={(collection) => {
                onChange?.({
                  publish: {
                    libraries: {
                      ...item?.publish?.libraries,
                      [library]: {
                        ...item?.publish?.libraries?.[library],
                        collection: collection._id,
                        includeItems: 'first_level',
                      },
                    },
                  },
                });
              }}
              trigger={
                <Button inline>
                  <Icon name="collection" />
                  Select collection
                </Button>
              }
            />
          }
        />
      )}
      {!!item?.publish?.libraries?.[library]?.collection && (
        <>
          <Input.Field
            card
            label={`Choose which ${library} files to include`}
            description="All files will be display in a flatten grid, as a normal collection"
            custom={
              <CheckboxCards
                value={item?.publish?.libraries?.[library]?.includeItems}
                onChange={(val) => {
                  onChange?.({
                    publish: {
                      libraries: {
                        ...item?.publish?.libraries,
                        [library]: {
                          ...item?.publish?.libraries?.[library],
                          includeItems: val,
                        },
                      },
                    },
                  });
                }}
                cards={[
                  {
                    title: 'Only first level',
                    description: `Will only include the direct ${library} files in this folder`,
                    value: 'first_level',
                  },
                  {
                    title: 'Include nested folders',
                    description: `All ${library} files in all nested folder within this folder will be included`,
                    value: 'nested',
                  },
                ]}
              />
            }
          />
        </>
      )}
    </>
  );
};

const SyncFromCollection = ({ item, library, onChange }) => {
  const Libraries = useLibraries();
  const App = useAppContext();

  const collection = Libraries[library].data.collections?.find(
    (c) => c._id === item?.publish?.libraries?.[library]?.importFromCollection,
  );

  React.useEffect(() => {
    if (
      !collection?._id &&
      typeof item?.publish?.libraries?.[library]?.importFromCollection ===
        'string'
    ) {
      onChange?.({
        publish: {
          libraries: {
            ...item?.publish?.libraries,
            [library]: {
              ...item?.publish?.libraries?.[library],
              importFromCollection: false,
            },
          },
        },
      });
    }
  }, [collection, item]);

  return (
    <>
      {collection?._id ? (
        <Flex style={{ gap: 15, marginBottom: 15 }}>
          <Input.Field
            card
            label={
              <div>
                Automatically adding all {Libraries[library].type} from
                collection <strong>{collection?.name}</strong> to this folder
              </div>
            }
            style={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
            custom={
              <>
                <Flex>
                  {/* <Button
                    inline
                    primary
                    onClick={() => App.toggleEdit('collection', collection)}
                  >
                    <Icon name="pencil-fill" />
                    Edit collection
                  </Button> */}
                  <SelectCollections
                    dropdown
                    context={Libraries[library]}
                    ignoreFamily
                    dropdownProps={{ width: 300, noTriggerWrapper: true }}
                    onChange={(collection) => {
                      onChange?.({
                        publish: {
                          libraries: {
                            ...item?.publish?.libraries,
                            [library]: {
                              ...item?.publish?.libraries?.[library],
                              collection: collection._id,
                            },
                          },
                        },
                      });
                    }}
                    trigger={
                      <Button inline>
                        <Icon name="collection" />
                        Change collection
                      </Button>
                    }
                  />
                  <Button
                    inline
                    onClick={() => {
                      onChange?.({
                        publish: {
                          libraries: {
                            ...item?.publish?.libraries,
                            [library]: {
                              ...item?.publish?.libraries?.[library],
                              importFromCollection: null,
                            },
                          },
                        },
                      });
                    }}
                  >
                    <Icon name="x-lg" />
                    Remove sync
                  </Button>
                </Flex>
              </>
            }
          />
          <DocumentCollection
            context={Libraries[library]}
            style={{ padding: 0 }}
            collection={collection}
          />
        </Flex>
      ) : (
        <Input.Field
          card
          label={`Automatically add all ${library} from a collection to this folder`}
          description="Files added to the collection will automatically be added to the folder. This can easily be turned off."
          custom={
            <SelectCollections
              dropdown
              context={Libraries[library]}
              ignoreFamily
              dropdownProps={{ width: 300, noTriggerWrapper: true }}
              onChange={(collection) => {
                onChange?.({
                  publish: {
                    libraries: {
                      ...item?.publish?.libraries,
                      [library]: {
                        ...item?.publish?.libraries?.[library],
                        importFromCollection: collection._id,
                      },
                    },
                  },
                });
              }}
              trigger={
                <Button inline>
                  <Icon name="collection" />
                  Select collection
                </Button>
              }
            />
          }
        />
      )}
    </>
  );
};

const FolderForm = ({ onOpen, item, onSave, onClose, preferences }) => {
  const Assets = usePickitDrive();
  const Media = useMediaContext();
  const User = useUserContext();
  const { generateUrl } = useStorageUrl();
  const [isSaving, setIsSaving] = React.useState(false);
  const [thumbnailUrl, setThumbnailUrl] = React.useState();
  const [thumbnailFile, setThumbnailFile] = React.useState();
  const [customThumbnailOriginal, setCustomThumbnailOriginal] =
    React.useState('');
  const [isEditThumb, setIsEditThumb] = React.useState(false);
  const [isSelectThumbnailFile, setIsSelectThumbnailFile] =
    React.useState(false);
  const [forceTab, setForceTab] = React.useState(false);
  const [pickitStock, setPickitStock] = React.useReducer(
    (state, action) => [...action],
    [],
  );
  const [stockPage, setStockPage] = React.useState(-1);
  const [settings, setSettings] = useDeepState(
    {
      access: {
        share_to: 'none',
      },
      preferences: {
        thumbnail: {
          display_as: 'collection',
        },
      },
    },
    {
      arrayMerge: (_destinationArray, sourceArray) => [...sourceArray],
      customMerge(key) {
        if (key === 'libraries') {
          return (a, b) => {
            return { ...b };
          };
        }
      },
    },
  );
  const uploadButton = React.useRef();

  async function loadPickitStock() {
    const newImages = await User.request.proxy.getPickitProviderSearch(
      {
        page_nr: 1,
        page_limit: 500,
        sort_order: 'asc',
        sort_field: 'best_match',
        explicitly_level: User.spaces?.selected.content_filter,
      },
      '15912-emma',
    );
    setStockPage(0);
    setPickitStock(shuffle(newImages.data));
  }

  async function loadFolder(item) {
    const data = await Assets.loadFolder(item?._id);
    setSettings(data?.folder);
  }

  React.useEffect(() => {
    if (item?._id && settings?._id !== item?._id) {
      if (preferences?.defaultTab) {
        setForceTab(preferences?.defaultTab);
      }
      loadFolder(item);
    }
  }, [item]);

  React.useEffect(() => {
    if (
      stockPage &&
      pickitStock.slice(stockPage * 6, stockPage * 6 + 6)?.length < 6
    ) {
      setStockPage(0);
    }
  }, [stockPage]);
  React.useEffect(() => {
    if (!pickitStock?.length) {
      loadPickitStock();
    }
  }, []);

  async function uploadThumbnails(files) {
    const uploadedFiles = [];
    if (!files?.length) {
      return [];
    }
    for (let i = 0; i < files.length; i++) {
      const controller = new AbortController();
      const file = files[i];
      const uploadedFile = await Media.uploadFile(file, 'thumbnails', {
        onProgress: () => {},
        abort: controller,
        onFinish: () => {},
        onError: () => {},
      });
      if (file.alias) {
        uploadedFile.alias = file.alias;
      }
      uploadedFiles.push(uploadedFile);
    }
    return uploadedFiles;
  }

  async function save(callback) {
    setIsSaving(true);
    if (customThumbnailOriginal?.name && thumbnailFile?.name) {
      if (settings?.preferences?.thumbnail?.custom?.id) {
        thumbnailFile.id = settings.preferences.thumbnail.custom.id;
      }
      if (settings?.preferences?.thumbnail?.custom_original?.id) {
        customThumbnailOriginal.id =
          settings.preferences.thumbnail.custom_original.id;
      }
      const [custom, custom_original] = await uploadThumbnails([
        thumbnailFile,
        customThumbnailOriginal,
      ]);
      settings.preferences.thumbnail.custom = custom;
      settings.preferences.thumbnail.custom_original = custom_original;
    }
    await onSave?.(settings);

    callback?.();
    setIsSaving(false);
  }

  function renderChildDistribution(child_distribution, child) {
    return (
      <>
        {User?.spaces?.selected?.slug === settings.communitySlug && (
          <Switch
            label="Enable"
            onChange={() => {
              if (!settings.distribution?.find((c) => c.slug === child.slug)) {
                setSettings({
                  distribution: [
                    ...(settings.distribution || []),
                    child_distribution,
                  ],
                });
              } else {
                settings.distribution.splice(
                  settings.distribution.findIndex((c) => c.slug === child.slug),
                  1,
                );
                setSettings({
                  distribution: [...(settings.distribution | [])],
                });
              }
            }}
            active={settings.distribution?.find((c) => c.slug === child.slug)}
          />
        )}
        {settings.distribution?.find((c) => c.slug === child.slug) && (
          <>
            <Input
              label="Share to"
              description=""
              key={`distribution_${child.slug}`}
              placeholder="None"
              custom={
                <>
                  <Dropdown
                    noClear
                    value={child_distribution.access?.share_to || 'all'}
                    options={[
                      { value: 'all', text: 'All users' },
                      {
                        value: 'specific',
                        text: 'Specific users',
                      },
                      {
                        value: 'none',
                        text: 'Admins only',
                      },
                    ]}
                    useV2
                    onChange={(selectedDistribution) => {
                      setSettings({
                        distribution: (settings.distribution || []).map(
                          (distribution_config) =>
                            distribution_config.slug === child.slug
                              ? {
                                  ...distribution_config,
                                  access: {
                                    ...distribution_config.access,
                                    share_to: selectedDistribution,
                                  },
                                }
                              : distribution_config,
                        ),
                      });
                    }}
                  />
                  {child_distribution?.access?.share_to === 'specific' && (
                    <>
                      <SelectUsersDropdown
                        style={{ marginTop: 10 }}
                        value={child_distribution?.access?.share_to_users || []}
                        placeholder="Select users"
                        communitySlug={child.slug}
                        onChange={(users) => {
                          setSettings({
                            distribution: settings.distribution.map(
                              (distribution_config) =>
                                distribution_config.slug === child.slug
                                  ? {
                                      ...distribution_config,
                                      access: {
                                        ...distribution_config.access,
                                        share_to_users: users,
                                      },
                                    }
                                  : distribution_config,
                            ),
                          });
                        }}
                      />
                      <SelectGroupsDropdown
                        value={
                          child_distribution?.access?.share_to_user_groups || []
                        }
                        placeholder="Select groups"
                        style={{ marginTop: 10 }}
                        communitySlug={child.slug}
                        onChange={(groups) => {
                          setSettings({
                            distribution: settings.distribution.map(
                              (distribution_config) =>
                                distribution_config.slug === child.slug
                                  ? {
                                      ...distribution_config,
                                      access: {
                                        ...distribution_config.access,
                                        share_to_user_groups: groups,
                                      },
                                    }
                                  : distribution_config,
                            ),
                          });
                        }}
                      />
                    </>
                  )}
                </>
              }
            />

            {child_distribution?.access?.share_to && (
              <>
                {User?.spaces?.selected?.slug === settings.communitySlug && (
                  <Switch
                    style={{ marginTop: 10 }}
                    label="Enable sub-account admins to change these settings"
                    onChange={(val) => {
                      setSettings({
                        distribution: settings.distribution.map(
                          (distribution_config) =>
                            distribution_config.slug === child.slug
                              ? {
                                  ...distribution_config,
                                  open_settings: {
                                    share_to: val,
                                    thumbnail_size: val,
                                  },
                                }
                              : distribution_config,
                        ),
                      });
                    }}
                    active={child_distribution.open_settings?.thumbnail_size}
                  />
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }

  const isParentPublished = settings?.path
    ?.reverse()
    ?.reduce((isPublished, folder) => {
      if (typeof isPublished === 'boolean') {
        return isPublished;
      }
      if (folder.publish?.is_force_unpublished) {
        isPublished = false;
      }
      if (folder.publish?.is_published && !folder.publish?.only_direct_items) {
        isPublished = folder;
      }
      return isPublished;
    }, null);
  const parentThatIsPublished = settings?.path?.find((path) => {
    return path?.publish?.is_published;
  });

  return (
    <Popup
      isOpen
      onOpen={() => {
        onOpen?.();
      }}
      zIndex={99}
      onClose={onClose}
      title="Folder settings"
      contentStyle={{ height: '70vh', padding: 0 }}
      actions={(isOpen, setIsOpen) => (
        <Flex width="100%" justifyContent="space-between">
          <Button pixi inline onClick={onClose}>
            Cancel
          </Button>
          <Button
            primary
            inline
            pixi
            loading={isSaving}
            onClick={() => save(() => setIsOpen(false))}
          >
            Save changes
          </Button>
        </Flex>
      )}
    >
      <Form.Tabs activeTabId={forceTab} onChange={() => setForceTab('')}>
        <Form.Tab
          id="information"
          label={
            <>
              <Icon name="info-circle" />
              Information
            </>
          }
        >
          <Input.Field
            label="Name"
            autoFocus
            value={settings.name}
            onChange={(name) =>
              setSettings({
                name,
              })
            }
          />
        </Form.Tab>
        <Form.Tab
          id="publish"
          label={
            <>
              <Icon name="megaphone" />
              Publish
            </>
          }
        >
          {(isParentPublished || settings.publish?.is_published) && (
            <>
              <Input.Field
                label="Publish name"
                autoFocus
                value={settings.publish?.name || settings.name}
                onChange={(name) =>
                  setSettings({
                    publish: {
                      name,
                    },
                  })
                }
              />
            </>
          )}
          {((!settings.publish?.is_force_unpublished && isParentPublished) ||
            !isParentPublished) &&
            !settings.publish?.is_force_unpublished && (
              <>
                <Input.Field
                  card
                  label="Publish folder"
                  disabled={isParentPublished}
                  description="The folder will be accessible by non-admins. You can control who will access this."
                  custom={
                    <Switch
                      label="Enabled"
                      active={
                        isParentPublished || settings.publish?.is_published
                      }
                      onChange={(value) =>
                        setSettings({
                          publish: {
                            is_published: value,
                          },
                        })
                      }
                    />
                  }
                />
                {isParentPublished &&
                  !settings.publish?.is_force_unpublished && (
                    <Message info icon="info-circle">
                      <p>
                        Folder is automatically published via folder{' '}
                        <strong>{parentThatIsPublished?.name}</strong>.
                        <br />
                        You can still control who can access this folder under{' '}
                        <Button
                          onClick={() => setForceTab('access')}
                          size="tiny"
                          inline
                          color="#3f8fe3"
                          style={{
                            marginLeft: 4,
                            marginRight: 4,
                            marginBottom: 6,
                            top: 2,
                            positon: 'relative',
                          }}
                        >
                          <Icon name="lock-fill" />
                          Access
                        </Button>{' '}
                        or forcefully unpublish the folder if you want to break
                        the inheritance for this folder and its items.
                      </p>
                    </Message>
                  )}
                {!isParentPublished &&
                settings.publish?.is_published &&
                !settings.publish?.is_force_unpublished ? (
                  <Input.Field
                    card
                    label="Publish only direct items"
                    description="All folders and nested folders will not be published"
                    custom={
                      <Switch
                        label="Enabled"
                        active={settings.publish?.only_direct_items}
                        onChange={(val) =>
                          setSettings({
                            publish: {
                              only_direct_items: val,
                            },
                          })
                        }
                      />
                    }
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          {(settings.publish?.is_published || isParentPublished) &&
            !!settings?.path?.length &&
            !settings.publish?.is_force_unpublished && (
              <Input.Field
                card
                label="Publish to root folder"
                description="The folder will be published in the root folder"
                custom={
                  <Switch
                    label="Enabled"
                    active={settings.publish?.to_root}
                    onChange={(value) =>
                      setSettings({
                        publish: {
                          to_root: value,
                        },
                      })
                    }
                  />
                }
              />
            )}

          <Input.Field
            card
            label="Access"
            custom={
              <>
                <CheckboxCards
                  value={settings.access?.share_to || 'none'}
                  minified={settings.access?.share_to === 'specific'}
                  onChange={(val) =>
                    setSettings({
                      access: {
                        share_to: val,
                      },
                    })
                  }
                  cards={[
                    {
                      value: 'all',
                      title: 'All users',
                      description:
                        'Access is inherited via context, either board or authentication. All authenticated can access this folder.',
                    },
                    {
                      value: 'authenticated',
                      title: 'Only authenticated users',
                      description:
                        'All authenticated users can access this folder.',
                    },
                    {
                      value: 'specific',
                      title: 'Specific users',
                      description:
                        'Select specific users that can access this folder. All admins can still access it.',
                    },
                    {
                      value: 'none',
                      title: 'Admins only',
                      description: 'Only admins can access this folder.',
                    },
                  ]}
                />
                {settings.access?.share_to === 'specific' && (
                  <div style={{ marginTop: 10 }}>
                    <SelectUsersDropdown
                      placeholder="Select specific users"
                      value={settings.access.share_to_users || []}
                      onChange={(users) =>
                        setSettings({ access: { share_to_users: users } })
                      }
                    />
                    <SelectGroupsDropdown
                      placeholder="Select specific groups"
                      style={{ marginTop: 10 }}
                      value={settings.access?.share_to_user_groups || []}
                      onChange={(groups) =>
                        setSettings({
                          access: { share_to_user_groups: groups },
                        })
                      }
                    />
                  </div>
                )}
              </>
            }
          />
          {isParentPublished ? (
            <>
              <Input.Field
                card
                label="Unpublish folder"
                description="Forcefully unpublish this folder and all its items"
                custom={
                  <Switch
                    label="Enabled"
                    active={settings.publish?.is_force_unpublished}
                    onChange={(value) =>
                      setSettings({
                        publish: {
                          is_force_unpublished: value,
                        },
                      })
                    }
                  />
                }
              />
            </>
          ) : (
            ''
          )}
        </Form.Tab>
        <Form.Tab
          id="sync"
          onlyIconsWhenActive
          label={
            <>
              <Icon name="back" />
              Sync to collection
            </>
          }
        >
          <Form.Tabs>
            <Form.Tab id="media" label="Media" icon="image">
              <SyncToCollection
                library="media"
                item={settings}
                onChange={setSettings}
              />
            </Form.Tab>
            <Form.Tab id="documents" label="Documents" icon="file-earmark-text">
              <SyncToCollection
                library="documents"
                item={settings}
                onChange={setSettings}
              />
            </Form.Tab>
          </Form.Tabs>
        </Form.Tab>
        <Form.Tab
          id="sync"
          onlyIconsWhenActive
          label={
            <>
              <Icon name="front" />
              Sync from collection
            </>
          }
        >
          <Form.Tabs>
            <Form.Tab id="media" label="Media" icon="image">
              <SyncFromCollection
                library="media"
                item={settings}
                onChange={setSettings}
              />
            </Form.Tab>
            <Form.Tab id="documents" label="Documents" icon="file-earmark-text">
              <SyncFromCollection
                library="documents"
                item={settings}
                onChange={setSettings}
              />
            </Form.Tab>
          </Form.Tabs>
        </Form.Tab>
        <Form.Tab
          id="thumbnail"
          hidden
          label={
            <>
              <Icon name="Image" />
              Thumbnail
            </>
          }
          header={
            <>
              <h1>Thumbnail</h1>
              <p>
                The folder will be published as a collection for your end-users
              </p>
            </>
          }
        >
          {!thumbnailFile?.name &&
          !settings?.preferences?.thumbnail?.custom?.id ? (
            <>
              {thumbnailUrl && (
                <ImageEditor
                  visible={thumbnailUrl && isEditThumb}
                  preferences={{
                    imageCropAspectRatio: 4 / 3,
                    imageCropLimitToImage: true,
                    previewImageDataMaxSize: {
                      width: 1024,
                      height: 1024,
                    },
                  }}
                  key={thumbnailUrl?.length}
                  onComplete={(file, design, originalFile) => {
                    setThumbnailUrl(URL.createObjectURL(file));
                    setThumbnailFile(file);
                    setSettings({
                      preferences: {
                        thumbnail: {
                          custom_design: JSON.stringify(design),
                        },
                      },
                    });
                    setCustomThumbnailOriginal(originalFile);
                    setIsEditThumb(false);
                  }}
                  onHide={() => setIsEditThumb(false)}
                  src={thumbnailUrl}
                />
              )}
              <input
                type="file"
                style={{ display: 'none' }}
                ref={uploadButton}
                accept="image/*"
                onChange={(event) => {
                  const files = Array.from(event.target.files);
                  const uploadedFile = files[0];
                  if (
                    !uploadedFile.type.includes('image/png') &&
                    !uploadedFile.type.includes('image/jpeg')
                  ) {
                    ToastService.createToast({
                      message:
                        'Only PNG and JPEG are supported as custom thumbnails. If you want other formats, upload and select them from Media.',
                    });
                    return false;
                  }
                  setThumbnailUrl(URL.createObjectURL(uploadedFile));
                  setIsEditThumb(true);
                }}
              />
              <Flex width="100%" style={{ gap: 25 }} alignItems="flex-start">
                <div style={{ width: '50%' }}>
                  <Input.Field
                    label="Custom thumbnail"
                    custom={
                      <>
                        <Button
                          as="div"
                          onClick={async () => {
                            const files = await selectFile({
                              libraryId: 'media',
                            });
                            setThumbnailUrl(
                              files[0]?.file?.previews?.[0]?.url ||
                                files[0]?.file?.previews?.[1]?.url ||
                                files[0]?.file?.url,
                            );
                            setIsEditThumb(true);
                          }}
                        >
                          <Icon name="Image" />
                          Select from media
                        </Button>
                        <Button
                          as="div"
                          onClick={() => uploadButton?.current?.click()}
                          style={{ marginLeft: 0, marginTop: 10 }}
                        >
                          <Icon name="CloudUploadFill" />
                          Upload image
                        </Button>
                      </>
                    }
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <Input.Field
                    labelStyle={{ marginTop: 0 }}
                    label={
                      <Flex width="100%" alignItems="flex-end">
                        <div>Select from Pickit Stock</div>
                        <Button
                          onClick={() => setStockPage(stockPage + 1)}
                          style={{ marginLeft: 'auto' }}
                          size="tiny"
                          inline
                        >
                          <Icon name="arrow-clockwise" />
                          Get new
                        </Button>
                      </Flex>
                    }
                    custom={
                      <Flex
                        width="100%"
                        flexWrap="wrap"
                        alignItems="flex-start"
                        style={{ gap: 1 }}
                      >
                        {pickitStock
                          .slice(stockPage * 6, stockPage * 6 + 6)
                          .map((image) => (
                            <img
                              key={image.id}
                              alt={image.description}
                              src={image.web}
                              onClick={() => {
                                setThumbnailUrl(
                                  image.download_links?.large?.url,
                                );
                                setIsEditThumb(true);
                              }}
                              style={{
                                width: '33.1%',
                                height: 115,
                                objectFit: 'cover',
                                cursor: 'pointer',
                                borderRadius: 5,
                              }}
                            />
                          ))}
                      </Flex>
                    }
                  />
                </div>
              </Flex>
            </>
          ) : (
            <>
              <Input.Field
                label="Preview"
                custom={
                  <Flex alignItems="flex-start">
                    <PublishedFolder
                      disabled
                      item={settings}
                      preferences={{
                        as_published: 1,
                      }}
                      thumbnail={
                        <img
                          alt={item?.name}
                          src={
                            thumbnailFile?.name ? (
                              URL.createObjectURL(thumbnailFile)
                            ) : settings?.preferences?.thumbnail?.custom?.id ? (
                              generateUrl(
                                settings?.preferences?.thumbnail?.custom?.url,
                              )
                            ) : !settings?.preferences?.thumbnail?.from_media
                                ?.id ? (
                              <FileThumbnailV2
                                file={
                                  !settings?.preferences?.thumbnail?.from_media
                                }
                              />
                            ) : undefined
                          }
                        />
                      }
                      collection={{
                        name: item?.name,
                      }}
                      style={{ width: '100%', maxWidth: 240, padding: 0 }}
                    />
                    <div style={{ marginLeft: 15 }}>
                      <Button
                        onClick={() => {
                          setThumbnailUrl('');
                          setThumbnailFile(null);
                        }}
                        pixi
                      >
                        <Icon name="arrow-repeat" />
                        Replace image
                      </Button>
                      <Dropdown
                        value={settings?.preferences?.thumbnail?.display_as}
                        placeholder="Display as"
                        onChange={(style) =>
                          setSettings({
                            preferences: {
                              thumbnail: {
                                display_as: style,
                              },
                            },
                          })
                        }
                        style={{ marginTop: 10 }}
                      >
                        <Dropdown.Option value="collection">
                          Collection
                        </Dropdown.Option>
                        <Dropdown.Option value="collection_group">
                          Collection group
                        </Dropdown.Option>
                      </Dropdown>
                      {(!settings?.preferences?.thumbnail?.display_as ||
                        settings?.preferences?.thumbnail?.display_as ===
                          'collection') && (
                        <Dropdown
                          value={settings?.preferences?.thumbnail?.style}
                          placeholder="Title style"
                          onChange={(style) =>
                            setSettings({
                              preferences: {
                                thumbnail: {
                                  style: style || 'classic',
                                },
                              },
                            })
                          }
                          style={{ marginTop: 10 }}
                        >
                          <Dropdown.Option value="classic">
                            Classic
                          </Dropdown.Option>
                          <Dropdown.Option value="hidden">
                            Hidden
                          </Dropdown.Option>
                          <Dropdown.Option value="bold">Bold</Dropdown.Option>
                        </Dropdown>
                      )}
                    </div>
                  </Flex>
                }
              />
            </>
          )}
        </Form.Tab>
        <Form.Tab
          id="files"
          label={
            <>
              <Icon name="toggles2" />
              Preferences
            </>
          }
        >
          <Input.Field
            card
            label="Export as pptx"
            description="Enable export of all slides in this folder as pptx"
            custom={
              <Switch
                label="Enabled"
                active={settings.preferences?.enable_pptx_export}
                onChange={(value) =>
                  setSettings({
                    preferences: {
                      enable_pptx_export: value,
                    },
                  })
                }
              />
            }
          />
        </Form.Tab>
        <Form.Tab
          id="files"
          label={
            <>
              <Icon name="file-earmark" />
              Files
            </>
          }
          disabled
          disabledMessage="Coming soon"
          header={
            <>
              <h1>Files</h1>
              <p>Control how files will look at be accessed</p>
            </>
          }
        >
          <Input.Field
            custom={
              <CheckboxCards
                value={settings.access?.include_nested_folders}
                onChange={(val) =>
                  setSettings({
                    access: {
                      include_nested_folders: val,
                    },
                  })
                }
                cards={[
                  {
                    value: true,
                    title: 'Include nested folders',
                    description:
                      'Permissions can still be controlled individually for each folder.',
                  },
                  {
                    value: false,
                    title: 'Only direct files',
                    description:
                      'Only files in the first level will be displayed.',
                  },
                ]}
              />
            }
          />
          <Input.Field
            card
            label="Download as zip"
            description="Anyone that has access to this folder will be able to download it as a zip"
            custom={
              <Switch
                label="Enabled"
                active={settings.preferences?.enable_zip_download}
                onChange={(value) =>
                  setSettings({
                    preferences: {
                      enable_zip_download: value,
                    },
                  })
                }
              />
            }
          />
        </Form.Tab>
        <Form.Tab
          id="contributors"
          label={
            <>
              <Icon name="People" />
              Contributors
            </>
          }
          disabled
          disabledMessage="Coming soon"
          header={
            <>
              <h1>Contributors</h1>
              <p>
                Control who can upload and manage this folder that is not an
                admin
              </p>
            </>
          }
        >
          <SelectContributorUsersPixi
            onlyForm
            keyValue="id"
            contributor_users={settings?.access?.contributor_users || []}
            external_users={settings?.access?.share_to_external_users || []}
            display="email"
            onSave={(users) => {
              const newExternalUsers = users
                .filter((user) => {
                  return (
                    user.type === 'external' &&
                    !settings?.access?.share_to_external_users?.includes(
                      user.email,
                    )
                  );
                })
                .map((user) => user.email);
              setSettings({
                access: {
                  share_to_external_users: [
                    ...(settings?.access?.share_to_external_users || []),
                    ...newExternalUsers,
                  ],
                  contributor_users: [
                    ...users.map((user) => {
                      return {
                        user_id: user.id,
                        email: user.email,
                        default_upload_sharing: user.default_upload_sharing,
                        uploads_need_approval: user.uploads_need_approval,
                      };
                    }),
                  ],
                },
              });
            }}
            trigger={
              <Button pixi inline as="div">
                Select contributors
                {!!settings.access?.contributor_users?.length &&
                  ` (${settings.access.contributor_users.length} selected)`}
              </Button>
            }
          />
        </Form.Tab>
        {!User.spaces?.selected?.family_children.length ? (
          <></>
        ) : (
          <Form.Tab
            id="family"
            // hidden={!User.spaces?.selected?.family_children.length}
            disabled
            disabledMessage="Coming soon"
            header={
              <>
                <h1>Pickit Family</h1>
                <p>
                  Choose which sub-accounts to share the collection with, then
                  select thumbnail size and what users can see. Sub-accounts
                  can't make any changes to this collection unless given
                  permission.
                </p>
              </>
            }
            label={
              <>
                <Icon name="Clouds" />
                Pickit Family
              </>
            }
          >
            <List cards>
              {[
                ...(settings.distribution || []),
                ...(User.spaces?.selected?.family_children
                  .filter(
                    (child) =>
                      !settings.distribution?.find(
                        (c) => c.slug === child.slug,
                      ),
                  )
                  .map((child) => {
                    const default_distribution = {
                      slug: child.slug,
                      access: { share_to: settings.access?.share_to },
                    };
                    return (
                      settings?.distribution?.find(
                        (distri_child) => distri_child.slug === child.slug,
                      ) || default_distribution
                    );
                  }) || []),
              ]
                ?.sort((a, b) => {
                  const childA = User.spaces?.selected?.family_children.find(
                    (child) => child.slug === a.slug,
                  );
                  const childB = User.spaces?.selected?.family_children.find(
                    (child) => child.slug === b.slug,
                  );
                  return childA.name.localeCompare(childB.name);
                })
                ?.map((child_distribution) => {
                  const child = User.spaces?.selected?.family_children.find(
                    (child) => child.slug === child_distribution.slug,
                  );
                  // if (!child_distribution.access) {
                  //   child_distribution.access = {};
                  // }

                  return (
                    <List.Item
                      icon={
                        <Icon
                          bubble
                          button
                          onClick={() => {
                            if (
                              !settings.distribution?.find(
                                (c) => c.slug === child.slug,
                              )
                            ) {
                              setSettings({
                                distribution: [
                                  ...(settings.distribution || []),
                                  child_distribution,
                                ],
                              });
                            } else {
                              settings.distribution.splice(
                                settings.distribution.findIndex(
                                  (c) => c.slug === child.slug,
                                ),
                                1,
                              );
                              setSettings({
                                distribution: [
                                  ...(settings.distribution || []),
                                ],
                              });
                            }
                          }}
                          name={
                            settings?.distribution?.find(
                              (distri_child) =>
                                distri_child.slug === child.slug,
                            )
                              ? 'CheckCircleFill'
                              : 'Circle'
                          }
                        />
                      }
                      title={child.name}
                      key={child.slug}
                    >
                      {renderChildDistribution(child_distribution, child)}
                    </List.Item>
                  );
                })}
            </List>
          </Form.Tab>
        )}
        <Form.Tab
          id="access"
          label={
            <>
              <Icon name="LockFill" />
              Access
            </>
          }
          header={
            <>
              <h1>Access</h1>
              <p>Control who can access the contents of this folder</p>
            </>
          }
        />
        <Form.Tab
          id="external_access"
          label={
            <>
              <Icon name="LockFill" />
              External access
            </>
          }
          header={
            <>
              <h1>External Access</h1>
              <p>
                Control who can access the contents of this folder outside of
                your account
              </p>
            </>
          }
        >
          {/* <SelectExternalUsers
            value={settings.access?.share_to_external_users}
            onChange={(value) =>
              setSettings({ access: { share_to_external_users: value } })
            }
          /> */}
          {/* <Input.Field
            card
            label="Allow anonymous users to access this collection"
            description="Anyone with a link to this folder will access it without signing in."
            custom={
              <Switch
                label="Enabled"
                active={settings.access?.share_to_anonymous}
                onChange={(value) =>
                  setSettings({ access: { share_to_anonymous: value } })
                }
              />
            }
          /> */}
          {/* {settings.access?.share_to_anonymous && (
            <Input.Field
              card
              label="Disable sharing controls for this folder"
              description="All users will see sharing controls as this folder is accessible without signing in."
              custom={
                <Switch
                  label="Enabled"
                  active={settings.preferences?.hide_anonymous_sharing}
                  onChange={(value) =>
                    setSettings({
                      preferences: {
                        hide_anonymous_sharing: value,
                      },
                    })
                  }
                />
              }
            />
          )} */}
        </Form.Tab>
        {/* <Form.Tab
          disabled
          style={{ padding: 0, marginTop: 'auto', opacity: 1 }}
          label={
            <>
              <Input.Field
                label="Preview"
                custom={
                  <DocumentCollection
                    disabled
                    icon={{
                      name: 'FolderFill',
                      label: 'Folder',
                    }}
                    collection={{
                      name: item?.name,
                    }}
                    style={{ maxWidth: '100%', padding: 0 }}
                  />
                }
              />
            </>
          }
        >
          hej
        </Form.Tab> */}
      </Form.Tabs>
    </Popup>
  );
};

export default FolderForm;
