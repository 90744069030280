import { useMediaContext } from 'hooks';
import React, { useEffect, useReducer, useState } from 'react';
import { Confirm, Dropdown, Icon } from 'elements_v2';
import PixiDropdown from '@pixi/elements/Dropdown';
import { Box, Divider, TextInput } from '@mantine/core';
import { useGetFileIdFromLibrary } from 'hooks/files/useParsedFile';
import { FileThumbnailV2 } from 'components/FileThumbnail';

interface AssetPreviewFacialRecognitionSelectProps {
  target: JSX.Element;
  group?: { _id: string }; // Add _id property to the group type
  onSubmit?: () => void;
}

export default function AssetPreviewFacialRecognitionSelect({
  target,
  group,
  onSubmit,
}: AssetPreviewFacialRecognitionSelectProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [faceGroups, setFaceGroups] = useState<
    {
      _id: string;
      name: string;
      faces: { faceId: string; assetId: string }[];
    }[]
  >([]);

  const [mergeFace, setMergeFace] = useReducer(
    (state: any, action: any) => ({ ...action }),
    {},
  );

  const Media: any = useMediaContext();
  const getFile = useGetFileIdFromLibrary('media');

  useEffect(() => {
    getFaces(1);
  }, []);

  async function getFaces(page_nr = 1) {
    const data = await (Media as any).request.getFaces(page_nr);
    if (Media.setData) {
      Media.setData({
        type: 'merge',
        data: data.files,
        key: '_id',
        store: 'files',
      });
    }
    if (page_nr !== 1) {
      setFaceGroups((faces) =>
        [...faces, ...data.faces].map((face, key) => ({
          ...face,
          name: face.name || `Person #${key}`,
        })),
      );
    } else {
      setFaceGroups(
        data.faces.map((f: any, key: number) => {
          return {
            ...f,
            name: f.name || `Person #${key}`,
          };
        }),
      );
    }
  }

  function getProfileImage(newGroup: any) {
    const res: any = newGroup.faces
      .filter((face: any) => !!getFile(face.assetId)?._id)
      .map((face: any) => getFile(face.assetId))
      .find((image: any) => image?.vision?.faces?.length === 1)?.file;
    return res;
  }

  const faceComperator = (a: { name: string }, b: { name: string }) => {
    if (!a?.name) return 1;
    if (!b?.name) return -1;
    return a?.name.localeCompare(b?.name);
  };

  return (
    <PixiDropdown target={target} height={400}>
      <PixiDropdown.Label>Merge with</PixiDropdown.Label>
      <Box p="xs" onClick={(e) => e.stopPropagation()}>
        <TextInput
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>
      {faceGroups
        .sort(faceComperator)
        .filter((face) => {
          return face.name
            ? face?.name.toLowerCase().includes(searchQuery.toLowerCase())
            : Number(searchQuery) ===
                faceGroups?.findIndex((g) => g?._id === face?._id);
        })
        .map((newGroup, key) => {
          const toName = mergeFace?.to?.name;
          const fromName = mergeFace?.from?.name;

          return (
            <Confirm
              key={group?._id}
              useV2
              isForceOpen={
                mergeFace?.from?._id === group?._id &&
                mergeFace?.to?._id === newGroup?._id
              }
              overlay
              forceClosed={mergeFace?.from?._id !== group?._id}
              title={`Merge ${fromName} with ${toName}`}
              description={`${fromName} is the same person as ${toName}. This can't be undone.`}
              onClose={() => setMergeFace({})}
              onCancel={() => setMergeFace({})}
              onConfirm={async () => {
                const { savedGroup, updatedFiles } =
                  await Media.request.mergeFaces(
                    mergeFace?.from?._id,
                    mergeFace?.to?._id,
                  );
                Media.setData({
                  type: 'merge',
                  store: 'files',
                  data: updatedFiles,
                  key: '_id',
                });
                getFaces();
                setMergeFace({});
                onSubmit && onSubmit();
              }}
            >
              <Dropdown.Option
                key={newGroup?._id}
                disabled={newGroup._id === group?._id}
                onClick={async () => {
                  setMergeFace({
                    from: group,
                    to: newGroup,
                  });
                }}
              >
                {newGroup.name ? (
                  <>
                    {getProfileImage(newGroup)?.url ? (
                      <div
                        style={{
                          width: 30,
                          height: 30,
                          borderRadius: '50%',
                          overflow: 'hidden',
                          marginRight: '1rem',
                        }}
                      >
                        <FileThumbnailV2
                          file={getProfileImage(newGroup)}
                          preferSize="small"
                          imageStyle={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                          noVideo
                        />
                      </div>
                    ) : (
                      <Icon
                        name="person-circle"
                        style={{ width: 30, height: 30 }}
                      />
                    )}
                  </>
                ) : (
                  ''
                )}
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {newGroup.name}
                </div>
              </Dropdown.Option>
            </Confirm>
          );
        })}
    </PixiDropdown>
  );
}
