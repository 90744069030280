import { Box, Grid, Table } from '@mantine/core';
import { useAssetGridContext } from './AssetGridContext';
import AssetCard from './components/AssetCard';
import { Gallery, ThumbnailImageProps } from 'react-grid-gallery';
import { Ref, RefObject } from 'react';
import { Tab } from 'elements';
import { TableFields } from './Helpers';

function RenderAssetGallery({ imageProps }: ThumbnailImageProps) {
  const { src: assetId } = imageProps;
  const AssetGrid = useAssetGridContext();
  const file = AssetGrid.allFiles.find((file) => file._id === assetId);
  return (
    <Box style={{ ...imageProps.style, cursor: 'pointer' }} p={10}>
      {!file?._id ? (
        <Box />
      ) : (
        <AssetCard
          as="image"
          file={file}
          key={file._id}
          h="100%"
          style={{
            aspectRatio: 'none',
            height: '100%',
          }}
          gridId={AssetGrid.id}
          onClick={async (event, fileToolbar) => {
            await AssetGrid.onFileClick?.(
              event,
              file,
              fileToolbar,
              AssetGrid.allFiles,
            );
          }}
          fileToolbar={AssetGrid.fileToolbar}
          fileToolbarCustom={AssetGrid.fileToolbarCustom}
        />
      )}
    </Box>
  );
}
const AssetGridRender = ({
  files,
  containerRef,
}: {
  files: Pickit.FileInterface[];
  containerRef?: RefObject<HTMLDivElement>;
}) => {
  const AssetGrid = useAssetGridContext();
  if (AssetGrid.displayAs === 'table') {
    return (
      <Table
        w="100%"
        styles={{
          td: {
            padding: 5,
            fontSize: 'var(--mantine-font-size-xs)',
          },
        }}
        verticalSpacing={0}
        horizontalSpacing={0}
      >
        {AssetGrid.disable?.includes('tableHeader') ? (
          <></>
        ) : (
          <Table.Thead>
            {!AssetGrid.disable?.includes('selection') && (
              <Table.Th id="selection" />
            )}
            <Table.Th w="0" id="context_menu" />
            {AssetGrid.tableFields?.map((field) => (
              <Table.Th id={field} key={field} fw="500">
                {AssetGrid.disable?.includes('tableHeader')
                  ? ''
                  : field === 'file.previews'
                    ? ''
                    : TableFields[field]?.label || field}
                {/* {files.sortBy?.column === field && sortIcon} */}
              </Table.Th>
            ))}
          </Table.Thead>
        )}
        <Table.Tbody>
          {files.map((file) => (
            <AssetCard
              key={file._id}
              gridId={AssetGrid.id}
              file={file}
              onClick={async (event, fileToolbar) => {
                await AssetGrid.onFileClick?.(
                  event,
                  file,
                  fileToolbar,
                  AssetGrid.allFiles,
                );
              }}
              as="table"
              fileToolbar={AssetGrid.fileToolbar}
              fileToolbarCustom={AssetGrid.fileToolbarCustom}
            />
          ))}
        </Table.Tbody>
      </Table>
    );
  }
  if (AssetGrid.displayAs === 'grid') {
    const span = () => {
      if (AssetGrid.gridSize === 'large') {
        // This checks for larger screens and adjusts the span to prevent the images from being too large.
        switch (AssetGrid.viewport) {
          case 'xs':
          case 'sm':
          case 'md':
            return 12; // Full width on smaller screens
          case 'lg':
          case 'xl':
            return 6; // One third width on extra-large screens, for better appearance
          default:
            return 12;
        }
      }
      if (AssetGrid.gridSize === 'small') {
        switch (AssetGrid.viewport) {
          case 'xs':
          case 'sm':
            return 4; // Adjust the values as per your design requirements
          case 'md':
          case 'lg':
            return 2;
          case 'xl':
            return 1.5; // Small grids on extra-large screens can be smaller
          default:
            return 3;
        }
      } else {
        switch (AssetGrid.viewport) {
          case 'xxs':
            return 6;
          case 'xs':
            return 6;
          case 'sm':
            return 6;
          case 'md':
            return 4;
          case 'lg':
          case 'xl':
            return 3; // Slightly larger than 'lg' but still maintains multiple columns
          default:
            return 3;
        }
      }
    };

    return (
      <Grid w="100%" gutter="xs">
        {files.map((file) => {
          return (
            <Grid.Col span={span()} key={file._id}>
              <AssetCard
                file={file}
                gridId={AssetGrid.id}
                onClick={async (event, fileToolbar) => {
                  await AssetGrid.onFileClick?.(
                    event,
                    file,
                    fileToolbar,
                    files,
                  );
                }}
                fileToolbar={AssetGrid.fileToolbar}
                fileToolbarCustom={AssetGrid.fileToolbarCustom}
              />
            </Grid.Col>
          );
        })}
      </Grid>
    );
  }
  return (
    <Gallery
      enableImageSelection={false}
      margin={0}
      images={files.map((file) => {
        return {
          src: file._id,
          width: file.file?.image_details?.width || 200,
          height: file.file?.image_details?.height || 200,
        };
      })}
      thumbnailStyle={{
        width: '100%',
        height: '100%',
        margin: 0,
        background: 'transparent',
      }}
      thumbnailImageComponent={RenderAssetGallery}
    />
  );
};

export default AssetGridRender;
