import {
  AssetActionDropdownOptionGroup,
  AssetActionHookReturnType,
  AssetActionIds,
  AssetActionOption,
  AssetToolbarDownloadWrapper,
} from '@pixi/components/AssetActions/Types';
import PixiConfirm, { PixiConfirmProps } from '@pixi/elements/Confirm';
import { PixiDropdownProps } from '@pixi/elements/Dropdown';
import { cloneElement, useState } from 'react';
import {
  inOffice,
  isAddin,
  isNative,
  inAdobeAddon,
  supportedAdobeFormats,
} from 'utils/platform';

import { useTextContext, useUserContext } from 'hooks';
import useInsert from 'hooks/files/useInsert';
import { isAfter, isBefore } from 'date-fns';
import {
  bytesToSize,
  copyTextToClipboard,
  downloadUrl,
  openInNewTab,
} from 'utils';
import useStorageUrl from 'hooks/files/useStorageUrl';
import useContextFromFile from 'hooks/useContextFromFile';
import useUserStatus from 'hooks/useUserStatus';
import localStorageWrapper from 'utils/localStorageWrapper';
import { ToastService } from 'elements';
import { useNative } from 'views/Native/context';
import { analytics, insert } from 'services';
import PixiIcon from '@pixi/elements/Icon';
import {
  Checkbox,
  FileInput,
  Image,
  Stack,
  Switch,
  TextInput,
} from '@mantine/core';
import PixiForm from '@pixi/elements/Form';
import {
  AppConfigInterface,
  createAppToast,
  openFileImageEditor,
  openAssetPreview,
  openFileSettingsPopup,
} from '@pixi/AppController';
import FeedbackForm from '@pixi/components/AssetQuickForms/FeedbackForm';
import { FolderIdToName } from '@pixi/components/DataCollectors/FolderIdToName';
import {
  getMultiStore,
  useConfigStoreValue,
  useDataStoreCallback,
  useMultiDataStoreCallback,
} from '@pixi/store';
import { useAssetGridContext } from '@pixi/components/AssetGrid/AssetGridContext';
import SelectFolder from '@pixi/components/SelectFolder';
import PixiText from '@pixi/elements/Text';
import TransferForm from '@pixi/components/AssetQuickForms/TransferForm';
import { WysiwygPreview } from 'elements_v2/Input/Wysiwyg';
import PixiButton from '@pixi/elements/Button';
import { useAppContext } from 'contexts/Providers/AppProvider';
import { AssetGridContextInterface } from '../AssetGrid/Helpers';
import useCloudStorage from 'hooks/useCloudStorage';
import PixiTooltip from '@pixi/elements/Tooltip';

export function getPreviewPrefs({
  file,
  allFiles,
  extraPrefs,
}: {
  file: Pickit.FileInterface;
  allFiles: Pickit.FileInterface[];
  extraPrefs?: AppConfigInterface['filePreview']['prefs'];
}) {
  if (!file?._id) {
    return {};
  }
  const index = allFiles?.findIndex((f) => f._id === file._id);
  if (index === -1) {
    return {};
  }
  const nextFile = allFiles?.[index + 1];
  const prevFile = allFiles?.[index - 1];
  return index >= 0
    ? {
        keys: [
          ...(prevFile
            ? [
                {
                  char: '←',
                  eventKey: 'ArrowLeft',
                  label: 'Previous',
                  autoCapture: true,
                  onClick: () => {
                    openAssetPreview(
                      prevFile,
                      getPreviewPrefs({ file: prevFile, allFiles, extraPrefs }),
                    );
                  },
                },
              ]
            : []),
          ...(nextFile
            ? [
                {
                  char: '→',
                  eventKey: 'ArrowRight',
                  label: 'Next',
                  autoCapture: true,
                  onClick: () => {
                    openAssetPreview(
                      nextFile,
                      getPreviewPrefs({ file: nextFile, allFiles, extraPrefs }),
                    );
                  },
                },
              ]
            : []),
        ],
        ...(extraPrefs || {}),
      }
    : {};
}
export const useAssetActions = ({
  file: _file,
  confirmProps,
  dropdownProps,
  filesInContext: _filesInContext,
  readOnly,
  files,
}: {
  file: Pickit.FileInterface;
  files?: Pickit.FileInterface[];
  confirmProps?: Partial<PixiConfirmProps>;
  dropdownProps?: Partial<
    Omit<PixiDropdownProps, 'title' | 'onSubmit' | 'target'>
  >;
  filesInContext?: Pickit.FileInterface[];
  readOnly?: boolean;
  assetGridContext?: AssetGridContextInterface;
}) => {
  const file = useDataStoreCallback(
    'FILES',
    (data, store) => {
      if (_file?._id && !store.getByRow(_file)) {
        return _file;
      }
      return store.getByRow(_file) || _file;
    },
    [_file?._id],
  ) as Pickit.FileInterface;
  const App = useAppContext();
  const AssetGrid = useAssetGridContext();
  const filesInContext = _filesInContext || AssetGrid?.allFiles;
  const Text: any = useTextContext();
  const context = useContextFromFile(file);
  const isManage = useConfigStoreValue('APP_VIEW', 'isManage');
  const User = useUserContext();
  const Native: any = useNative();
  const { generateUrl } = useStorageUrl();
  const userStatus: any = useUserStatus();
  const cloudStorage = useCloudStorage();
  const [isDoingAssetAction, setIsDoingAssetAction] = useState(false);
  const isMultiSelect = !!files?.length && files.length > 1;
  const downloadTerms = useConfigStoreValue('APP_CONFIG', 'termsDownload');

  if (readOnly === undefined && !isManage) {
    readOnly = true;
  }

  async function copyFile() {
    setIsDoingAssetAction(true);
    await Native.copyFileToClipboard?.([
      {
        file,
        fileUrl: generateUrl(file?.file?.url),
      },
    ]);
    ToastService.createToast({
      message: 'Copied to clipboard',
    });
    setIsDoingAssetAction(false);
  }
  async function openAndEditFile() {
    setIsDoingAssetAction(true);

    await Native.openAndEditFile({
      file,
      fileUrl: generateUrl(file?.file?.url),
    });
    setIsDoingAssetAction(false);
  }
  const { insertImage, downloadImage, openFile, insertSlide, insertFile } =
    useInsert();

  const isDownload =
    !isAddin() ||
    file?.file?.contentType === 'image/gif' ||
    file?.file?.contentType?.includes('video') ||
    file?.file?.contentType?.includes('audio');

  const isInDownloadBag = useMultiDataStoreCallback(
    'FILES_SELECTED',
    'downloadBag',
    (store) => {
      return !!store.getByRow(file);
    },
    [file],
  );

  const licenseExpired =
    file?.license?.expirationDate &&
    isBefore(new Date(file?.license?.expirationDate), new Date());

  const availability = {
    insertSlide:
      !!inOffice() &&
      !!window.Office.context.requirements.isSetSupported(
        'PowerPointApi',
        '1.2',
      ),
    copy: isNative() && !file?.file?.external_file && !licenseExpired,
    insertImage:
      (!!file?.file?.previews?.length ||
        file?.file?.ext === 'svg' ||
        file?.file?.ext === 'png' ||
        file?.file?.ext === 'jpg' ||
        file?.file?.ext === 'jpeg' ||
        file?.file?.ext === 'gif') &&
      file.type === 'media' &&
      !isDownload,
    open: isNative() ? true : file?.type === 'documents',
    open_and_edit:
      isNative() &&
      !file?.file?.external_file &&
      userStatus?.product?.isBusinessAdmin &&
      file?.file?.ext !== 'potx' &&
      file?.file?.ext !== 'xltx' &&
      file?.file?.ext !== 'dotx',
    download: !!file?.file?.url && !licenseExpired,
    isDownloadOnly: isDownload,
    leaveFeedback: false,
  };
  const { adobeProduct } = window;
  if (inAdobeAddon() && adobeProduct) {
    if (
      supportedAdobeFormats[adobeProduct]?.includes(
        file?.file?.ext?.toLowerCase() as string,
      )
    ) {
      availability.insertImage = true;
    }
    availability.download = true;
  }

  let downloadWrapper: AssetToolbarDownloadWrapper | null = null;

  if (downloadTerms?.active) {
    downloadWrapper = (target, onClick, label) => (
      <PixiForm<{ accepted: boolean }>
        width={340}
        title="Terms"
        type="dropdown"
        submitLabel={label || 'Download'}
        submitProps={({ accepted }) => ({ disabled: !accepted })}
        withCancel
        form={{
          accepted: {
            key: 'accepted',
            value: false,
            render: ({ value, setValue }) => (
              <Stack>
                <PixiText size="sm">
                  <WysiwygPreview value={downloadTerms?.message} />
                </PixiText>
                <PixiButton
                  leftSection={
                    <PixiIcon
                      name={value ? 'toggle-large-on' : 'toggle-large-off'}
                      size={22}
                    />
                  }
                  color="dark"
                  variant="light"
                  onClick={() => {
                    setValue(!value);
                  }}
                >
                  I agree to the terms
                </PixiButton>
              </Stack>
            ),
          },
        }}
        onSubmit={() => {
          onClick();
        }}
        target={target}
      />
    );
  }

  const feedbackSettings =
    User.data?.selectedCommunity?.settings?.preferences?.feedback;
  const feedbackPrefs = feedbackSettings?.find(
    (prefs: { library: string }) => prefs.library === file.type,
  );

  const activeLibraryId = AssetGrid?.libraries
    ? AssetGrid?.libraries?.[0]
    : file.libraries?.[0] || file?.type;
  const libraries = (User.data?.selectedCommunity?.libraries ||
    []) as Pickit.CommunityLibraryInterface[];
  const activeLibrary = libraries?.find((l) => activeLibraryId === l.nameId);
  const librarySettings = activeLibrary?.preferences;

  const allowUserFeedback =
    file.file?.allowFeedback ||
    // currentCollection?.allowFileFeedback ||
    feedbackPrefs?.allowFileFeedback;

  const allowExternalUserFeedback =
    file.file?.allowFeedbackFromExternal ||
    // currentCollection?.allowFeedbackFromExternal ||
    feedbackPrefs?.allowFeedbackFromExternal;

  const userCanEditFile =
    !readOnly &&
    ((App.isManage && userStatus?.product?.isBusinessAdmin) ||
      file?.file?.uploaded_by === User.djangoProfile?.id);

  const api = {
    insertSlide: (file: Pickit.FileInterface['file'], prefs?: any) =>
      insertSlide(file, 'slides', prefs),
    downloadFile: (file: Pickit.FileInterface['file']) => downloadImage(file),
    openFile: (file: Pickit.FileInterface['file']) => openFile(file),
    insertFile: (file: Pickit.FileInterface['file']) => insertFile(file),
    copyFile,
    createVariant: () =>
      openFileImageEditor(file, {
        onComplete: async (variantFile: File, prefs) => {
          const controller = new AbortController();
          const uploadedFile = await context.uploadFile(
            variantFile,
            'thumbnails',
            {
              onProgress: () => {},
              abort: controller,
              onFinish: () => {},
              onError: () => {},
            },
          );
          uploadedFile.width = prefs?.design?.crop?.width;
          uploadedFile.height = prefs?.design?.crop?.height;
          await context.savePartial({
            _id: file?._id,
            $push: {
              'file.variants': uploadedFile,
            },
          });
        },
      }),
  };

  const previewsShouldBeDownloadable =
    !!file?.file?.previews?.length &&
    file.type !== 'documents' &&
    !file?.file?.contentType?.includes('video') &&
    !file?.file?.contentType?.includes('audio') &&
    file?.file?.ext !== 'gif' &&
    file?.file?.ext !== 'zip' &&
    (User.data?.selectedCommunity?.settings?.preferences
      ?.disableVectorDownloadRestriction
      ? true
      : file?.file?.ext !== 'svg' &&
        file?.file?.ext !== 'eps' &&
        file?.file?.ext !== 'ai' &&
        file?.file?.ext !== 'pdf');

  const showSlides =
    file.file?.slides_published ??
    User.data?.selectedCommunity?.settings?.preferences
      ?.enableQuickSlidePreview;
  const data: AssetActionOption[] = [];

  data.push({
    id: 'preview',
    label: 'Preview',
    onClick: () => {
      openAssetPreview?.(
        file,
        getPreviewPrefs({ file, allFiles: filesInContext }),
      );
    },
    icon: 'expand',
  });

  if (!file.file?.external_file && !licenseExpired && isNative()) {
    data.push({
      id: 'open',
      label: 'Open',
      customRender: downloadWrapper
        ? (trigger) =>
            downloadWrapper(trigger, () =>
              Native.openFile({
                file,
                fileUrl: generateUrl(file?.file?.url),
              }),
            )
        : undefined,
      onClick: () =>
        Native.openFile({
          file,
          fileUrl: generateUrl(file?.file?.url),
        }),
      icon: 'folder-open',
    });
  }

  if (
    !file.file?.external_file &&
    file.file?.open_url &&
    !licenseExpired &&
    // (file.file?.ext !== 'pptxslide' ||
    //   (inOffice() &&
    //     !window.Office.context.requirements.isSetSupported(
    //       'PowerPointApi',
    //       '1.2',
    //     ))) &&
    !isNative()
  ) {
    data.push({
      id: 'open_document',
      label: Text.get('documents/open'),
      onClick: () => openFile(file),
      customRender: downloadWrapper
        ? (trigger) =>
            downloadWrapper(trigger, () => openFile(file), 'Open document')
        : undefined,
      icon: 'arrow-up-right-from-square',
    });
  }

  if (availability.open_and_edit) {
    data.push({
      id: 'open_and_edit',
      label: 'Open and edit',
      customRender: downloadWrapper
        ? (trigger) =>
            downloadWrapper(trigger, openAndEditFile, 'Open and edit')
        : undefined,
      onClick: openAndEditFile,
      icon: 'pencil',
    });
  }

  const imageEditorAction: AssetActionOption = {
    id: 'image_editor',
    label: 'Image editor',
    icon: 'paintbrush-pencil',
    onClick: () =>
      openFileImageEditor(file, {
        onComplete: async (variantFile: File, prefs) => {
          const toBase64 = (file: File): Promise<string> =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result as string);
              reader.onerror = (error) => reject(error);
            });
          if (isAddin()) {
            const base64 = await toBase64(variantFile);
            await insert.fromMedia(
              base64.replace('data:image/png;base64,', ''),
              file?.name,
            );
            return;
          }

          const url = window.URL.createObjectURL(variantFile);

          // Create an anchor element (<a>) dynamically
          const a = document.createElement('a');
          a.href = url;
          a.download = file?.file?.name || `${file?.name}.${file?.file?.ext}`; // Set the default file name for saving

          // Append the anchor to the body of the document to make it work in Firefox
          document.body.appendChild(a);

          // Trigger the download
          a.click();

          // Clean up by revoking the URL and removing the anchor element
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        },
      }),
  };

  if (availability.insertImage && !licenseExpired) {
    const insert: AssetActionOption = {
      id: 'insert',
      label: Text.get('view/contribution/insert'),
      onClick: async () => {
        setIsDoingAssetAction(true);
        await insertImage(
          file,
          localStorageWrapper.getItem('pickitBOAIInsertSize') || 'default',
        );
        setIsDoingAssetAction(false);
      },
      icon: 'arrow-left',
      dropdownOptions: undefined,
    };

    if (downloadWrapper) {
      insert.customRender = (trigger) =>
        downloadWrapper(
          trigger,
          async () => {
            setIsDoingAssetAction(true);
            await insertImage(
              file,
              localStorageWrapper.getItem('pickitBOAIInsertSize') || 'default',
            );
            setIsDoingAssetAction(false);
          },
          'Insert',
        );
    }
    if (previewsShouldBeDownloadable) {
      insert.dropdownOptions = [];
      if (
        file.file?.contentType === 'image/jpeg' ||
        file.file?.contentType === 'image/png'
      ) {
        insert.dropdownOptions.push({
          groupLabel: 'Original',
          groupIcon: 'arrow-left',
          actions: [
            {
              id: 'insert_original',
              label: 'Original',
              onClick: () => insertImage(file, 'original'),
              icon: 'arrow-left',
            },
          ],
        });
      } else if (
        inAdobeAddon() &&
        adobeProduct &&
        supportedAdobeFormats[adobeProduct]?.includes(
          file?.file?.ext?.toLowerCase() as string,
        )
      ) {
        insert.dropdownOptions.push(
          {
            groupLabel: 'Original',
            groupIcon: 'arrow-left',
            actions: [
              {
                id: 'insert_original',
                label: 'Original',
                onClick: () => insertImage(file, 'original'),
                icon: 'arrow-left',
              },
            ],
          },
          ...(file?.file?.variants?.length
            ? [
                {
                  groupLabel: 'Variants',
                  groupIcon: 'palette',
                  actions: file?.file?.variants?.map((variantFile) => ({
                    id: variantFile.id,
                    label:
                      variantFile.alias || variantFile.name || variantFile.id,
                    onClick: () => insertImage(file, 'variant', variantFile.id),
                    icon: 'arrow-left',
                  })),
                } as AssetActionDropdownOptionGroup,
              ]
            : []),
        );
      }
      if (file.file?.previews?.length) {
        const insertMap = file.file?.previews?.map((preview) => {
          return {
            id: preview.name,
            label: `${preview.name?.charAt(0).toUpperCase()}${preview.name?.slice(1)}`,
            subLabel: preview.width && `${preview.width}x${preview.height}`,
            onClick: () => insertImage(file, preview.name),
            icon: 'arrow-left',
          } as AssetActionOption;
        });
        insert.dropdownOptions.push({
          groupLabel: 'Previews',
          groupIcon: 'image',
          actions: insertMap,
        });
      }

      if (file?.file?.variants?.length) {
        const variantMap = file?.file?.variants?.map(
          (variantFile) =>
            ({
              id: variantFile.id,
              label: variantFile.alias || variantFile.name || variantFile.id,
              addition: variantFile.width
                ? `${variantFile.width} x ${variantFile.height}`
                : variantFile.contentLength &&
                  bytesToSize(variantFile.contentLength),
              onClick: (event) => {
                event.preventDefault();
                event.stopPropagation();
                analytics.trackEvent('File Downloaded', {
                  File: file._id,
                  'File Download Type': 'Insert',
                  'File Download Variant': 'Variant',
                });
                insertImage(file, 'variant', variantFile.id);
              },
              icon: 'arrow-left',
            }) as AssetActionOption,
        );
        insert.dropdownOptions.push({
          groupLabel: 'Variants',
          groupIcon: 'palette',
          actions: variantMap,
        });
      }
    }

    data.push(insert);
  }

  if (availability.download && !file.file?.external_file && !licenseExpired) {
    const imageEditorClick = async (
      preset: Pickit.CommunityPresetInterface,
    ) => {
      return new Promise<void>((resolve, reject) => {
        openFileImageEditor(file, {
          forceCropping: {
            width: preset.width,
            height: preset.height,
          },
          onComplete: (File, settings) => {
            const url = window.URL.createObjectURL(File);

            // Create an anchor element (<a>) dynamically
            const a = document.createElement('a');
            a.href = url;
            a.download = file?.file?.name || `${file?.name}.${file?.file?.ext}`; // Set the default file name for saving

            // Append the anchor to the body of the document to make it work in Firefox
            document.body.appendChild(a);

            // Trigger the download
            a.click();

            // Clean up by revoking the URL and removing the anchor element
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            resolve();
          },
        });
      });
    };
    // Define the base structure for the download action
    const downloadAction: AssetActionOption & {
      dropdownOptions: AssetActionDropdownOptionGroup[];
    } = {
      id: 'download',
      label: Text.get('documents/download'),
      onClick: downloadWrapper
        ? undefined
        : () => downloadUrl(generateUrl(file.file?.url), file.file?.name),
      icon: 'arrow-down-to-line',
      customRender:
        !previewsShouldBeDownloadable && !file?.file?.variants?.length
          ? downloadWrapper
            ? (trigger) =>
                downloadWrapper(
                  cloneElement(trigger, {
                    onClick: () => {},
                  }),
                  () =>
                    downloadUrl(generateUrl(file.file?.url), file.file?.name),
                  'Download',
                )
            : undefined
          : undefined,
      dropdownOptions: [],
    };

    // Use conditional blocks to populate the dropdownOptions
    if (file.file?.previews?.length && previewsShouldBeDownloadable) {
      downloadAction.dropdownOptions.push({
        groupLabel: undefined,
        groupIcon: 'file-lines',
        actions: [
          {
            id: 'download_original',
            label: 'Source file',
            icon: 'arrow-down-to-line',
            subLabel: bytesToSize(file.file?.contentLength),
            customRender: downloadWrapper
              ? (trigger) => {
                  return downloadWrapper(trigger, () =>
                    downloadUrl(generateUrl(file.file?.url), file.file?.name),
                  );
                }
              : undefined,
            onClick: downloadWrapper
              ? undefined
              : () => downloadUrl(generateUrl(file.file?.url), file.file?.name),
          },
        ],
      });
      downloadAction.dropdownOptions.push({
        groupLabel: undefined,
        groupIcon: 'file-lines',
        withDivider: true,
        actions: [
          {
            id: 'previews',
            label: 'Smaller versions',
            icon: 'arrow-down-left-and-arrow-up-right-to-center',
            dropdownOptions: [
              {
                groupLabel: undefined,
                groupIcon: 'file-lines',
                actions: file.file.previews.map((preview) => ({
                  id: preview.name,
                  label: `${preview.name?.charAt(0).toUpperCase()}${preview.name?.slice(1)}`,
                  subLabel:
                    preview.width && `${preview.width}x${preview.height}`,
                  customRender: downloadWrapper
                    ? (trigger) =>
                        downloadWrapper(
                          trigger,
                          () =>
                            downloadUrl(
                              generateUrl(preview.url),
                              `${file.file?.name.split('.')[0]}-${preview.name}`,
                            ),
                          'Download',
                        )
                    : undefined,
                  onClick: downloadWrapper
                    ? undefined
                    : () =>
                        downloadUrl(
                          generateUrl(preview.url),
                          `${file.file?.name.split('.')[0]}-${preview.name}`,
                        ),
                })),
              },
            ],
          },
        ],
      });

      if (file.file?.variants?.length && context.type !== 'documents') {
        downloadAction.dropdownOptions.push({
          groupLabel: undefined,
          groupIcon: 'palette',
          actions: [
            {
              label: 'Variants',
              icon: 'hexagon-image',
              dropdownOptions: [
                {
                  actions: file.file.variants.map((variantFile, i) => ({
                    id: variantFile.id,
                    label:
                      variantFile.alias || variantFile.name || variantFile.id,
                    addition: variantFile.width
                      ? `${variantFile.width} x ${variantFile.height}`
                      : variantFile.contentLength &&
                        bytesToSize(variantFile.contentLength),
                    isCustomRenderWithoutClick: true,
                    customRender: downloadWrapper
                      ? (trigger) =>
                          downloadWrapper(
                            trigger,
                            () =>
                              downloadUrl(
                                generateUrl(variantFile.url),
                                (variantFile.alias || variantFile.name) +
                                  '.png',
                              ),
                            'Download',
                          )
                      : (target, prefs) => (
                          <PixiTooltip
                            zIndex={(prefs.rootZIndex || 1) + 1}
                            position="right"
                            label={
                              <Image
                                w={200}
                                mah={300}
                                fit="contain"
                                src={generateUrl(variantFile.url)}
                              />
                            }
                          >
                            {target}
                          </PixiTooltip>
                        ),
                    onClick: (event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      downloadUrl(
                        generateUrl(variantFile.url),
                        (variantFile.alias || variantFile.name) + '.png',
                      );
                    },
                  })),
                },
              ],
            },
          ],
        });
      }
      if (User.data?.selectedCommunity?.settings?.imageEditorPresets?.length) {
        downloadAction.dropdownOptions.push({
          groupLabel: undefined,
          groupIcon: 'crop',
          withDivider: true,
          actions: User.data.selectedCommunity.settings.imageEditorPresets.map(
            (preset: Pickit.CommunityPresetInterface) =>
              ({
                id: `download_custom_format`,
                label: preset.name,
                icon: 'crop',
                subLabel: preset.width && `${preset.width}x${preset.height}`,
                customRender: downloadWrapper
                  ? (trigger) =>
                      downloadWrapper(
                        trigger,
                        () => imageEditorClick(preset),
                        'Crop image',
                      )
                  : undefined,
                onClick: async () => {
                  return new Promise<void>((resolve, reject) => {
                    openFileImageEditor(file, {
                      forceCropping: {
                        width: preset.width,
                        height: preset.height,
                      },
                      onComplete: (File, settings) => {
                        const url = window.URL.createObjectURL(File);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download =
                          file?.file?.name ||
                          `${file?.name}.${file?.file?.ext}`;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                        resolve();
                      },
                    });
                  });
                },
              }) as AssetActionOption,
          ),
        });
      }
      if (
        !file?.file?.external_file &&
        (App.isManage || !librarySettings?.disableImageEditEndUser) &&
        User.authenticated
      ) {
        downloadAction.dropdownOptions.push({
          groupLabel: undefined,
          groupIcon: 'file-lines',
          withDivider: true,
          actions: [imageEditorAction],
        });
      }
    }

    // if (
    //   !file?.file?.external_file &&
    //   (App.isManage || !librarySettings?.disableImageEditEndUser) &&
    //   file?.file?.contentType?.startsWith('image')
    // ) {
    //   downloadAction.dropdownOptions.push({
    //     groupLabel: 'Download custom format',
    //     groupIcon: 'crop-simple',
    //     actions: [imageEditorAction],
    //   });
    // }

    // Finally push the structured download action into the data array
    data.push(downloadAction);
  }
  if (availability.copy && !licenseExpired && !file.file?.external_file) {
    data.push({
      id: 'copy',
      label: 'Copy to clipboard',
      onClick: copyFile,
      icon: 'clipboard',
    });
  }

  if (
    !file.file?.external_file &&
    !licenseExpired &&
    userCanEditFile &&
    file?.file?.url &&
    userStatus?.activeFeatures?.external_sharing
  ) {
    data.push({
      id: 'transfers',
      label: 'Shareable link',
      icon: 'share',
      width: 300,
      dropdownOptions: [
        {
          groupLabel: 'Shareable links',
          groupIcon: 'link',
          actions: [
            ...((file.transfers?.map((transfer) => ({
              id: transfer._id,
              label: transfer.name,
              icon:
                transfer.expiresAt && isAfter(new Date(), transfer.expiresAt)
                  ? 'triangle-exclamation'
                  : undefined,
              iconProps: {
                color: 'red',
              },
              // customRender: (trigger) => {
              //   return (
              //     <PixiTooltip
              //       bg="white"
              //       c="dark"
              //       zIndex={200}
              //       withinPortal
              //       label={
              //         <Stack gap="5">
              //           <PixiText
              //             size="xs"
              //             c={
              //               transfer.expiresAt &&
              //               isAfter(new Date(), transfer.expiresAt)
              //                 ? 'dimmed'
              //                 : undefined
              //             }
              //           >
              //             Created by{' '}
              //             <DjangoIdToName value={transfer.createdBy} />{' '}
              //             {formatDistanceToNow(transfer.createdAt)} ago
              //           </PixiText>
              //           <PixiText
              //             size="xs"
              //             c={
              //               transfer.expiresAt &&
              //               isAfter(new Date(), transfer.expiresAt)
              //                 ? undefined
              //                 : 'dimmed'
              //             }
              //           >
              //             {transfer.expiresAt
              //               ? isAfter(new Date(), transfer.expiresAt)
              //                 ? `Expired ${formatDistanceToNow(transfer.expiresAt)} ago`
              //                 : `Expires in ${formatDistanceToNow(transfer.expiresAt)}`
              //               : 'No expire date set'}
              //           </PixiText>
              //         </Stack>
              //       }
              //     >
              //       {trigger}
              //     </PixiTooltip>
              //   );
              // },
              actions: [
                {
                  id: 'copy_link',
                  icon: 'copy',
                  tooltip: 'Copy shareable link',
                  onClick: () => {
                    // copy to clipboard
                    const url = `${window.location.origin}/#/transfer/${transfer._id}`;
                    navigator.clipboard.writeText(url);
                    createAppToast({
                      message: 'Link copied to clipboard',
                      id: transfer._id as string,
                    });
                  },
                },
                {
                  id: 'edit',
                  icon: 'pen',
                  tooltip: 'Edit shareable link',
                  render(trigger, { setIsFreezeDropdown }) {
                    return (
                      <TransferForm
                        file={file}
                        onOpen={() => {
                          setIsFreezeDropdown?.(true);
                        }}
                        onClose={() => {
                          setIsFreezeDropdown?.(false);
                        }}
                        transfer={transfer}
                        target={trigger}
                        {...dropdownProps}
                      />
                    );
                  },
                },
                {
                  id: 'delete',
                  icon: 'trash-can',
                  tooltip: 'Delete shareable link',
                  render(trigger, { setIsFreezeDropdown }) {
                    return (
                      <PixiConfirm
                        title="Are you sure?"
                        description="The link will be deleted and will stop working."
                        zIndex={155}
                        onClose={() => {
                          setIsFreezeDropdown?.(false);
                        }}
                        onOpen={() => {
                          setIsFreezeDropdown?.(true);
                        }}
                        onMouseLeave={(event, { isOpen }) => {
                          if (!isOpen) {
                            setIsFreezeDropdown?.(false);
                          }
                        }}
                        onMouseOver={() => {
                          setIsFreezeDropdown?.(true);
                        }}
                        onConfirm={async () => {
                          await context.savePartial({
                            _id: file._id,
                            $pull: {
                              transfers: {
                                _id: transfer._id,
                              },
                            },
                          });
                        }}
                        {...confirmProps}
                      >
                        {trigger}
                      </PixiConfirm>
                    );
                  },
                },
              ],
              onClick: () => {
                openInNewTab(`/#/transfer/${transfer._id}`);
              },
            })) || []) as AssetActionOption[]),
            {
              id: 'create_transfer',
              label: 'Create new shareable link',
              color: 'primary',
              icon: 'plus',
              customRender: (trigger, { rootZIndex, setIsFreezeDropdown }) => {
                return (
                  <TransferForm
                    file={file}
                    target={trigger}
                    zIndex={152}
                    setIsFreezeDropdown={setIsFreezeDropdown}
                    {...dropdownProps}
                  />
                );
              },
            },
          ],
        },
      ],
    });
  }

  const publicUrl = `https://share.pickit.com/file/${context.type}/${file._id}`;

  data.push({
    label: 'Share',
    id: 'share_public_transfer',
    icon: 'share',
    dropdownOptions: [
      {
        groupLabel: 'Share',
        groupIcon: 'share',
        actions: [
          {
            label: 'Facebook',
            icon: 'facebook',
            onClick: () => {
              openInNewTab(
                `https://www.facebook.com/sharer/sharer.php?u=${publicUrl}`,
              );
            },
          },
          {
            label: 'LinkedIn',
            icon: 'linkedin',
            onClick: () => {
              openInNewTab(
                `https://www.linkedin.com/shareArticle?mini=true&url=${publicUrl}`,
              );
            },
          },
          {
            label: 'X (Twitter)',
            icon: 'x-twitter',
            onClick: () => {
              openInNewTab(
                `http://twitter.com/share?text=${file.name}&url=${publicUrl}`,
              );
            },
          },
          {
            label: 'Link',
            icon: 'link-horizontal',
            onClick: () => {
              copyTextToClipboard(publicUrl);
              createAppToast({
                message: 'Link copied to clipboard',
                id: file._id,
              });
            },
          },
          {
            label: 'Email',
            icon: 'envelope',
            onClick: () => {
              openInNewTab(
                `mailto:?subject=${Text.get('share/access').replace(
                  'XX',
                  file.name,
                )}&body=${Text.get('share/email_copy_file')}: \n\r${publicUrl}`,
              );
            },
          },
        ],
      },
    ],
  });

  const addToFolderAction: AssetActionOption = {
    label: 'Folders',
    id: 'folders',
    icon: 'folders',
    dropdownOptions: [
      {
        groupLabel: 'Add to folder',
        groupIcon: 'folder-plus',
        actions: [
          {
            icon: 'plus',
            label: 'Add to folder',
            id: 'add_to_folder',
            color: 'primary',
            withChevron: true,
            customRender: (
              trigger,
              { onClose, setIsFreezeDropdown, rootZIndex },
            ) => {
              return (
                <SelectFolder
                  target={trigger}
                  context={context}
                  trigger="hover"
                  value={file?.folders || []}
                  position="right-start"
                  zIndex={(rootZIndex || 15) + 10}
                  onSearch={(query) => {
                    setIsFreezeDropdown(!!query);
                  }}
                  onFolderClick={async (folder) => {
                    await context.savePartial({
                      _id: file._id,
                      $addToSet: {
                        folders: folder,
                      },
                    });
                    onClose?.();
                  }}
                />
              );
            },
          },
        ],
      },
      {
        groupLabel: 'Exists in folders',
        groupIcon: 'folder',
        actions: [
          ...((file.folders || [])
            ?.filter((f) => !!f)
            .map((f) => ({
              id: f,
              label: <FolderIdToName id={f} />,
              color: 'gray',
              onClick: async () => {
                // await context.addDocumentToFolder(file, f);
              },
              actions: [
                {
                  icon: 'xmark',
                  label: 'Remove from folder',
                  render: (trigger, { onClick }) => {
                    return (
                      <PixiConfirm
                        title="Remove from folder"
                        description="Are you sure you want to remove the file from the folder?"
                        onConfirm={async (closeConfirm) => {
                          await context.savePartial({
                            _id: file._id,
                            $pull: {
                              folders: f,
                            },
                          });
                          // await context.removeDocumentFromFolder(file, f);
                          closeConfirm(true);
                          onClick?.();
                        }}
                        confirmLabel="Remove"
                        confirmProps={{
                          leftSection: <PixiIcon name="trash-can" />,
                          color: 'red',
                        }}
                        {...confirmProps}
                      >
                        {trigger}
                      </PixiConfirm>
                    );
                  },
                },
              ],
              icon: 'folder',
            })) as AssetActionOption[]),
        ],
      },
    ],
  };

  if (
    file.file?.ext === 'pptxslide' &&
    inOffice() &&
    window.Office.context.requirements.isSetSupported('PowerPointApi', '1.2') &&
    !licenseExpired
  ) {
    data.push(
      ...[
        {
          id: 'insert_slide',
          label: Text.get('view/contribution/insert'),
          onClick: () => {
            setIsDoingAssetAction(true);
            insertSlide(file.file);
            setIsDoingAssetAction(false);
          },
          icon: 'arrow-left',
        } as (typeof data)[number],
        {
          id: 'insert_themed_slide',
          label: `${Text.get('view/contribution/insert')} - ${Text.get(
            'documents/insert_powerpoint_slide_with_theme',
          )}`,
          onClick: async () => {
            setIsDoingAssetAction(true);
            await insertSlide(file.file, 'slides', {
              formatting: 'UseDestinationTheme',
            });
            setIsDoingAssetAction(false);
          },
          icon: 'paintbrush',
        } as (typeof data)[number],
      ],
    );
  }

  if (file.file?.external_file && !licenseExpired) {
    data.push({
      id: 'open_external',
      label:
        Text.get(`ui/open_in/${file.import?.from}`) ||
        Text.get(`ui/open_in/general`),
      onClick: () => openFile(file),
      icon: 'arrow-up-right-from-square',
    });
  }
  if (
    file.file?.external_file &&
    file.file?.open_url &&
    !licenseExpired &&
    file.file?.url
  ) {
    data.push({
      id: 'download_external',
      label:
        Text.get(`ui/download_from/${file.import?.from}`) ||
        Text.get(`ui/download_from/general`),
      onClick: () => openInNewTab(file.file?.url),
      icon: 'cloud-arrow-down',
    });
  }
  if (User.authenticated && file?.file?.url) {
    data.push({
      id: 'favorite',
      label: Text.get('ui/favorite'),
      onClick: () => context.favoriteDocument(file._id),
      icon: 'heart',
      iconProps: {
        variant: cloudStorage?.data?.saved[context.type].files.includes(
          file._id,
        )
          ? 'filled'
          : undefined,
        color: cloudStorage?.data?.saved[context.type].files.includes(file._id)
          ? 'primary'
          : undefined,
      },
    });
  }

  if (
    !file.file?.external_file &&
    !licenseExpired &&
    file?.file?.url &&
    User.authenticated
  ) {
    data.push({
      id: 'add_to_bag',
      label: 'Add to bag',
      onClick: () =>
        getMultiStore('FILES_SELECTED', 'downloadBag').toggle(file),
      icon: isInDownloadBag ? 'bag-shopping' : 'bag-shopping-plus', // TODO: PIXI: Add iconProps (filled)
      iconProps: {
        variant: isInDownloadBag ? 'filled' : undefined,
      },
      color: isInDownloadBag ? 'primary' : undefined,
    });
  }

  if (!!file?.file?.slides?.length && showSlides !== false) {
    data.push({
      id: 'open_slides',
      label: 'Show slides',
      onClick: () => {
        openAssetPreview(file, {
          defaultView: 'slides',
        });
      },
      icon: 'notes',
    });
  }

  if (allowUserFeedback || allowExternalUserFeedback) {
    data.push({
      id: 'leave_feedback',
      label: 'Leave feedback',
      icon: 'comment',
      customRender: (trigger) => {
        return <FeedbackForm file={file} target={trigger} {...dropdownProps} />;
      },
    });
  }

  if (userCanEditFile) {
    if (!file?.file?.external_file && file?.file?.url) {
      data.push({
        id: 'upload_new_version',
        label: 'Upload new version',
        customRender: (trigger, { setIsFreezeDropdown, onClose }) => {
          return (
            <PixiForm<{
              file: File | undefined;
              isRegeneratePreview: boolean;
            }>
              title="Upload new version"
              type="dropdown"
              icon="arrow-up-from-line"
              onOpen={() => {
                setIsFreezeDropdown(true);
              }}
              onClose={() => {
                setIsFreezeDropdown(false);
              }}
              submitLabel="Upload new version"
              submitProps={(data) => ({
                disabled: !data.file,
              })}
              form={{
                file: {
                  key: 'file',
                  value: undefined,
                  render: ({ value, setValue }) => (
                    <FileInput
                      value={value}
                      placeholder="Select file"
                      onChange={(file) => {
                        if (file) {
                          setValue(file);
                        }
                      }}
                    />
                  ),
                },
                isRegeneratePreview: {
                  key: 'isRegeneratePreview',
                  value: false,
                  render: ({ value, setValue }) => (
                    <Checkbox
                      checked={value}
                      onChange={(event) =>
                        setValue(event.currentTarget.checked)
                      }
                      my="xs"
                      label="Regenerate preview"
                    />
                  ),
                },
              }}
              onSubmit={async ({ file: newFile, isRegeneratePreview }) => {
                if (!newFile) {
                  return;
                }
                const controller = new AbortController();
                await new Promise((resolve) => {
                  context.uploadFileVersion(
                    file,
                    newFile,
                    context.mainContainerId,
                    {
                      abort: controller,
                      onFinish: () => {
                        resolve(null);
                      },
                    },
                  );
                });
                if (isRegeneratePreview) {
                  setTimeout(() => {
                    context.reParseFile(file?._id, {
                      thumbnail: true,
                    });
                  }, 500);
                }
                setIsFreezeDropdown(false);
                onClose?.();
              }}
              target={trigger}
              {...dropdownProps}
            />
          );
        },
        icon: 'arrow-up-to-dotted-line',
      });
    }
    const settingsActions: AssetActionOption[] = [
      {
        id: 'advanced_settings',
        label: 'Advanced settings',
        icon: 'gear',
        onClick: () => openFileSettingsPopup(file),
      },
    ];

    if (file?.libraries?.includes('brandassets')) {
      settingsActions.push(addToFolderAction);
    }

    if (file?.type === 'media') {
      settingsActions.push({
        id: 'libraries',
        label: 'Add to library',
        icon: 'books',
        dropdownOptions: [
          {
            groupLabel: 'Add to library',
            groupIcon: 'books',
            actions: [
              {
                label: 'Media',
                id: 'add_to_media_library',
                icon:
                  file.libraries?.includes('media') || !file?.libraries?.length
                    ? 'square-check'
                    : 'square',
                iconProps: {
                  variant:
                    file.libraries?.includes('media') ||
                    !file?.libraries?.length
                      ? 'filled'
                      : undefined,
                },
                disabled:
                  !file?.libraries?.length ||
                  (file?.libraries?.length === 1 &&
                    file?.libraries?.[0] === 'media'),
                onClick: (event) => {
                  context.savePartial({
                    _id: file._id,
                    ...(file?.libraries?.includes('media')
                      ? {
                          $pull: {
                            libraries: 'media',
                          },
                        }
                      : {
                          $addToSet: {
                            libraries: 'media',
                          },
                        }),
                  });
                },
              },
              {
                label: 'Brand assets',
                id: 'add_to_brand_library',
                icon: file.libraries?.includes('brandassets')
                  ? 'square-check'
                  : 'square',
                iconProps: {
                  variant: file.libraries?.includes('brandassets')
                    ? 'filled'
                    : undefined,
                },
                onClick: async (event) => {
                  await context.savePartial({
                    _id: file._id,
                    ...(file?.libraries?.includes('brandassets')
                      ? {
                          $pull: {
                            libraries: 'brandassets',
                          },
                        }
                      : {
                          $addToSet: {
                            libraries: 'brandassets',
                          },
                        }),
                  });
                },
              },
            ],
          },
        ],
      });
    }
    if (userCanEditFile) {
      if (file?.trash?.isTrash) {
        settingsActions.push({
          id: 'delete',
          label: 'Delete permanently',
          icon: 'trash-can',
          color: 'red',
          customRender: (trigger, { onClose }) => {
            return (
              <PixiConfirm
                zIndex={200}
                title="Delete file"
                description="Are you sure you want to delete the file? This is permanent and can not be undone."
                onConfirm={async (closeConfirm) => {
                  await context.deleteDocument(file);
                  closeConfirm(true);
                  onClose?.();
                  createAppToast({
                    id: `deleted_file${file._id}`,
                    message: 'File was deleted successfully',
                    icon: 'trash-can',
                  });
                }}
                confirmLabel="Delete"
                confirmProps={{
                  leftSection: <PixiIcon name="trash-can" />,
                  color: 'red',
                }}
                {...confirmProps}
              >
                {trigger}
              </PixiConfirm>
            );
          },
        });
      } else {
        settingsActions.push({
          id: 'move_to_trash',
          label: 'Move to trash',
          color: 'red',
          icon: 'trash-can',
          customRender: (trigger, { onClose, setIsFreezeDropdown }) => {
            return (
              <PixiForm<{
                isDeletePermanently: boolean;
                deletePermanently: string;
              }>
                submitLabel={(data) =>
                  data.isDeletePermanently
                    ? 'Delete permanently'
                    : 'Move to trash'
                }
                onOpen={() => {
                  setIsFreezeDropdown(true);
                }}
                onClose={() => {
                  setIsFreezeDropdown(false);
                }}
                submitProps={(data) => ({
                  color: data.isDeletePermanently ? 'red' : 'primary',
                  disabled:
                    data.isDeletePermanently &&
                    data.deletePermanently.toLowerCase() !== 'yes',
                })}
                type="dropdown"
                zIndex={999}
                onSubmit={async (data) => {
                  if (data.deletePermanently?.toLowerCase() === 'yes') {
                    if (isMultiSelect) {
                      await context.deleteFiles(files.map((file) => file._id));
                    } else {
                      await context.deleteDocument(file);
                    }
                    createAppToast({
                      id: `deleted_file${file._id}`,
                      message: isMultiSelect
                        ? 'Files was deleted successfuly'
                        : 'File was deleted successfully',
                      icon: 'trash-can',
                    });
                  } else {
                    if (isMultiSelect) {
                      await context.saveDocumentsWhere(
                        {
                          _id: { $in: files?.map((file) => file._id) },
                        },
                        {
                          trash: {
                            isTrash: true,
                          },
                        },
                      );
                    } else {
                      await context.moveDocumentToTrash(
                        isMultiSelect ? files : file,
                      );
                    }
                    createAppToast({
                      id: `moved_${file._id}_to_trash`,
                      message: isMultiSelect
                        ? 'Files moved to trash successfully'
                        : 'File moved to trash successfully',
                      icon: 'trash-can',
                    });
                  }
                  onClose?.();
                }}
                form={{
                  isDeletePermanently: {
                    key: 'isDeletePermanently',
                    value: false,
                    render: ({ value, setValue }) => {
                      return (
                        <Switch
                          checked={value}
                          onChange={(event) =>
                            setValue(event.currentTarget.checked)
                          }
                          label="Delete permanently"
                        />
                      );
                    },
                  },
                  deletePermanently: {
                    key: 'deletePermanently',
                    value: '',
                    render: ({ value, setValue, data }) => {
                      if (!data.isDeletePermanently) {
                        return <></>;
                      }
                      return (
                        <TextInput
                          label="Write 'Yes' to confirm"
                          description="Deleting permanently can't be undone."
                          value={value}
                          autoFocus
                          onChange={(event) =>
                            setValue(event.currentTarget.value)
                          }
                        />
                      );
                    },
                  },
                }}
                target={trigger}
                onTargetClick={() => {
                  setIsFreezeDropdown(true);
                }}
              />
            );
          },
        });
      }
    }

    data.push({
      id: 'settings',
      label: 'More',
      icon: 'ellipsis',
      dropdownOptions: [
        {
          groupLabel: 'Settings',
          groupIcon: 'gear',
          actions: settingsActions,
        },
      ],
    });
    if (userCanEditFile) {
      if (file?.trash?.isTrash) {
        data.push({
          id: 'recover_from_trash',
          label: 'Recover from trash',
          icon: 'rotate-reverse',
          color: 'primary',
          onClick: async () => {
            await context.recoverDocumentsFromTrash([file]);
            createAppToast({
              id: `moved_${file._id}_from_trash`,
              message: 'File was recovered successfully',
              icon: 'rotate-reverse',
            });
          },
        });
      }
    }
  }
  const actions: Record<string, AssetActionOption> = data.reduce(
    (end: Record<string, AssetActionOption>, item) => {
      end[item.id] = item;
      return end;
    },
    {},
  );

  let primaryAction: null | AssetActionOption = actions?.download;

  if (licenseExpired) {
    primaryAction = null;
  }

  if (actions?.insert) {
    primaryAction = actions?.insert;
  }
  if (!primaryAction && actions?.download_external) {
    primaryAction = actions?.download_external;
  }

  if (!primaryAction && actions?.open_external) {
    primaryAction = actions?.open_external;
  }
  if (actions?.open_document) {
    primaryAction = actions?.open_document;
  }
  if (!primaryAction && actions?.open) {
    primaryAction = actions?.open;
  }

  if (isNative() && actions?.copy) {
    primaryAction = actions?.copy;
  }

  if (file?.data?.type === 'text') {
    primaryAction = actions?.favorite;
  }

  function getActionsFromItem(
    item: AssetActionOption,
    allActions: AssetActionOption[] = [],
  ) {
    if (!allActions.find((f) => f.id === item.id)) {
      allActions.push(item);
    }
    if (item.dropdownOptions?.length) {
      item.dropdownOptions.forEach((group) => {
        const actions = group.actions as AssetActionOption[];
        actions
          ?.filter((a) => !!a.id)
          .forEach((a) => {
            getActionsFromItem(a, allActions);
          });
      });
    }
    return allActions;
  }

  const actionsFlattened = data.reduce((allActions, item) => {
    const actions = getActionsFromItem(item);
    return [...new Set([...allActions, ...actions])];
  }, [] as AssetActionOption[]);

  return {
    toolbar: data || [],
    toolbarFlattened: actionsFlattened,
    specificActions: (order: AssetActionIds[], includeRest?: boolean) => {
      return [
        ...(order
          ?.filter((item) => !!actionsFlattened?.find((i) => i.id === item))
          .map((item) => actionsFlattened?.find((i) => i.id === item)) || []),
        ...(includeRest
          ? data?.filter((item) => !order.includes(item.id)) || []
          : []),
      ] as AssetActionOption[];
    },
    getAction: (id: AssetActionIds) => {
      return actionsFlattened?.find((i) => i.id === id);
    },
    availability,
    api,
    primaryAction,
    isDoingAssetAction,
  } as AssetActionHookReturnType;
};
