import { useEffect, useState } from 'react';
import { loadScript } from 'utils';
import Dialog from 'utils/Dialog';

export default function useGoogle() {
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [error, setError] = useState(undefined);
  const DefaultScope =
    'https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive.readonly';

  /**
   * Used for Sign in with Google on welcome page
   * Same credentials as our add-on.
   */
  async function signIn() {
    return new Promise(async (resolve, reject) => {
      const dialog = new Dialog(
        `${window.location.origin}/authgoogle.html`,
        1000,
        700
      );
      dialog.open(async (authToken) => {
        if (authToken && typeof authToken === 'string') {
          resolve(authToken);
        } else {
          // reject(false);
          return false;
        }
      });
    });
  }

  async function initGoogleAPI() {
    try {
      if (!window.gapi?.load) {
        await loadScript('https://apis.google.com/js/platform.js');
      }
      await window.gapi.load('client:auth2', async function () {
        console.log({
          api_key: window.pickit.keys.GOOGLE_API_KEY,
          client_id: window.pickit.keys.GOOGLE_CLIENT_ID,
        });
        try {
          await window.gapi.auth2.init({
            api_key: window.pickit.keys.GOOGLE_API_KEY,
            client_id: window.pickit.keys.GOOGLE_CLIENT_ID,
            scope: DefaultScope,
            discoveryDocs: [
              'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
            ],
          });
        } catch (e) {
          console.error(e);
          setError(e);
        }
        const auth = window.gapi.auth2.getAuthInstance();
        auth.isSignedIn.listen(updateSigninStatus);

        // Prepare User
        auth.currentUser.get();
        if (auth) {
          setConnectedStatus();
        }
      });
    } catch (e) {
      console.error(e);
      setError(e);
    }
  }

  async function request(path, options, scope) {
    if (!window.gapi?.auth2) {
      await loadScript('https://apis.google.com/js/platform.js');
    }
    const auth = window.gapi.auth2.getAuthInstance();
    if (!isConnected || !auth) {
      return false;
    }
    const fullScope = `https://www.googleapis.com/${scope}`;
    const GrantedScopes = await auth.currentUser.get().getGrantedScopes();

    if (!GrantedScopes.includes(fullScope)) {
      const GoogleUser = await auth.currentUser.get();
      await GoogleUser.grant({ fullScope });
    }
    const result = await window.gapi.client.request({
      method: 'GET',
      path,
      ...options,
    });
    return result.result;
  }

  async function updateSigninStatus() {
    setConnectedStatus();
  }

  async function setConnectedStatus() {
    if (!window.gapi?.auth2) {
      await loadScript('https://apis.google.com/js/platform.js');
    }
    const auth = window.gapi.auth2.getAuthInstance();
    if (!auth) {
      setIsConnected(false);
      return;
    }
    const user = auth.currentUser.get();
    const isAuthorized = user.hasGrantedScopes(DefaultScope);
    setIsConnected(isAuthorized);
  }

  async function connect() {
    setIsConnecting(true);
    if (!window.gapi?.auth2) {
      await loadScript('https://apis.google.com/js/platform.js');
    }
    const auth = window.gapi.auth2.getAuthInstance();
    if (!auth) {
      await initGoogleAPI();
    }

    try {
      await auth.signIn();
      setConnectedStatus();
    } catch (e) {
      console.error('wat', e);
    }
    setIsConnecting(false);
  }

  async function disconnect() {
    if (!window.gapi?.auth2) {
      await loadScript('https://apis.google.com/js/platform.js');
    }
    const auth = window.gapi.auth2.getAuthInstance();
    auth.disconnect();
    setIsConnected(false);
  }

  return {
    isConnected,
    isConnecting,
    connect,
    request,
    initGoogleAPI,
    disconnect,
    signIn,
    error,
  };
}
