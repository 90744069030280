import { Alert, AlertProps, Group } from '@mantine/core';
import { ReactNode } from 'react';
import PixiIcon, { PixiIconName } from '../Icon';
import PixiButton from '../Button';

export default function ErrorMessage({
  title,
  message,
  icon,
  tryAgain,
  ...rest
}: {
  title: ReactNode;
  message: ReactNode;
  tryAgain?: () => Promise<void> | void;
  icon?: PixiIconName;
} & Omit<Partial<AlertProps>, 'icon'>) {
  return (
    <Alert
      color="red"
      icon={<PixiIcon name={icon || 'triangle-exclamation'} />}
      title={title}
      {...rest}
    >
      {message}
      {tryAgain && (
        <Group mt="md" color="red">
          <PixiButton
            onClick={async () => {
              await tryAgain();
            }}
            color="red"
            leftSection={<PixiIcon name="arrow-rotate-right" />}
          >
            Try again
          </PixiButton>
        </Group>
      )}
    </Alert>
  );
}
