import { Box, Divider, Group, Loader, Paper, Stack } from '@mantine/core';
import { useAssetGridContext } from '../AssetGridContext';
import { ResponsivePie } from '@nivo/pie';
import { createColorShades } from '@pixi/Theme';
import PixiText, { PixiTitle } from '@pixi/elements/Text';
import { useDataStoreCallback } from '@pixi/store';
import { bytesToSize } from 'utils';
import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import { SectionCollapse, Title } from '@pixi/components/AssetPanel/elements';

export default function AssetGridStats() {
  const { metadata, ...AssetGrid } = useAssetGridContext();

  if (!metadata?.stats) {
    return <></>;
  }

  if (AssetGrid.status.isLoadingMetadata) {
    return (
      <Group w="100%" justify="center" mt="xl">
        <Loader mt="xl" color="gray" />
      </Group>
    );
  }

  return (
    <Stack w="100%" gap="md" p="md" py="lg">
      <Group w="100%" wrap="nowrap" gap="md">
        <Paper w="100%" ta="center" p="lg" bg="rgba(0, 0, 0, 0.015)">
          <PixiText c="dimmed" fw="600">
            {AssetGrid.status.totalFiles}
          </PixiText>
          <PixiText c="dimmed" size="xs">
            assets
          </PixiText>
        </Paper>
        <Paper w="100%" ta="center" p="lg" bg="rgba(0, 0, 0, 0.015)">
          <PixiText c="dimmed" fw="600">
            {bytesToSize(metadata?.stats?.totalSizeInBytes)}
          </PixiText>
          <PixiText c="dimmed" size="xs">
            size
          </PixiText>
        </Paper>
      </Group>
      <Group w="100%" wrap="nowrap" gap="md">
        <Paper w="100%" ta="center" p="lg" bg="rgba(0, 0, 0, 0.015)">
          <PixiText c="dimmed" fw="600">
            {AssetGrid.activeShortcutIds?.find((c) => c?.includes('trash'))
              ? 0
              : Math.round(metadata?.stats?.distributed * 100)}
            %
          </PixiText>
          <PixiText c="dimmed" size="xs">
            distributed
          </PixiText>
        </Paper>
        <Paper w="100%" ta="center" p="lg" bg="rgba(0, 0, 0, 0.015)">
          <PixiText c="dimmed" fw="600">
            {Math.round(metadata?.stats?.adminsOnly)}%
          </PixiText>
          <PixiText c="dimmed" size="xs">
            admins only
          </PixiText>
        </Paper>
      </Group>
      <PixiButton
        variant="light"
        bg="rgba(0, 0, 0, 0.015)"
        onClick={() => {
          AssetGrid.getFiles(true);
          AssetGrid.getAvailableFilters();
          AssetGrid.getMetadata();
        }}
        color="gray"
        leftSection={<PixiIcon name="arrow-rotate-right" />}
      >
        Reload
      </PixiButton>
    </Stack>
  );
}
